import { usePanelStore } from '@/stores/panel'
import { useTimeEntryStore } from '@/stores/timeEntry'
import timelinkStoresService from '@/services/timelink-stores.service'

// eslint-disable-next-line no-unused-vars
import panelService from '@/services/panel.service'
import { watch } from 'vue'
import { useProjectsStore } from '@/stores/projects'

/**
 *
 * @param {panelService} panel
 * @param {*} arr
 * @param {*} id
 * @param {*} item
 * @param {?{
 *  color: ?string,
 *  bgColor: ?string,
 *  deckColor: ?string,
 *  deckBgColor: ?string,
 *  callback: panelCallback,
 *  interval: panelCallback,
 *  intervalTime: ?number,
 *  timeout: panelCallback,
 *  timeoutTime: ?number,
 *  init: panelCallback
 * }} _options
 */
function setCPS(
  panel,
  arr,
  id,
  item,
  // eslint-disable-next-line no-unused-vars
  _options = {
    color: null,
    bgColor: null,
    deckColor: null,
    deckBgColor: null,
    callback: null,
    interval: null,
    intervalTime: null,
    timeout: null,
    timeoutTime: null,
    init: null
  }
) {
  if (item == null) {
    panel.resetId(arr, id)
    panel.setId(arr, id, {
      type: 'text',
      text: '',
      image: null,
      callback: _options.callback
    })
    return
  }
  panel.setId(arr, id, {
    type: 'image',
    text: item.acronym ?? item.name ?? '',
    image: null,
    color: _options.color ?? (item.color ? timelinkStoresService.generateForegroundColor(item.color) : null) ?? null,
    bgColor: _options.bgColor ?? item.color ?? null,
    init: (panelItem) => {
      panel.getImageFor(item, panelItem)
      let watcher = null
      watcher = (cps) => {
        return watch(cps.tl.image, () => {
          timelinkStoresService.removeWatcher('panel', panel.getHandlerName(panelItem))
          panel.setImageFor(cps, panelItem)
          timelinkStoresService.setOrRenewWatcher(
            'panel',
            panel.getHandlerName(panelItem),
            watcher(item)
          )
        })
      }
      timelinkStoresService.setOrRenewWatcher(
        'panel',
        panel.getHandlerName(panelItem),
        watcher(item)
      )
    },
    callback: _options.callback
  })
}

export { setCPS }
