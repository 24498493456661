import { usePanelStore } from '@/stores/panel'
import { useTimeEntryStore } from '@/stores/timeEntry'
import timelinkStoresService from '@/services/timelink-stores.service'

// eslint-disable-next-line no-unused-vars
import panelService from '@/services/panel.service'
import { watch, nextTick } from 'vue'
import { useProjectsStore } from '@/stores/projects'
import { setCPS } from './cups'

/**
 *
 * @param {panelService} panel
 * @param {*} arr
 * @param {*} id
 * @param {*} item
 * @param {?{
 *  color: ?string,
 *  bgColor: ?string,
 *  deckColor: ?string,
 *  deckBgColor: ?string,
 *  callback: panelCallback,
 *  interval: panelCallback,
 *  intervalTime: ?number,
 *  timeout: panelCallback,
 *  timeoutTime: ?number,
 *  init: panelCallback
 * }} _options
 */
function setProject(
  panel,
  arr,
  id,
  item,
  // eslint-disable-next-line no-unused-vars
  _options = {
    color: null,
    bgColor: null,
    deckColor: null,
    deckBgColor: null,
    callback: null,
    interval: null,
    intervalTime: null,
    timeout: null,
    timeoutTime: null,
    init: null
  }
) {
  setCPS(panel, arr, id, item, _options)
}

/**
 *
 * @param {panelService} panel
 * @param {*} arr
 * @param {*} id
 * @param {*} item
 * @param {?{
 *  color: ?string,
 *  bgColor: ?string,
 *  deckColor: ?string,
 *  deckBgColor: ?string,
 *  callback: panelCallback,
 *  interval: panelCallback,
 *  intervalTime: ?number,
 *  timeout: panelCallback,
 *  timeoutTime: ?number,
 *  init: panelCallback
 * }} _options
 */
function setSelectableProject(
  panel,
  arr,
  id,
  item,
  // eslint-disable-next-line no-unused-vars
  _options = {
    callback: null,
    interval: null,
    intervalTime: null,
    timeout: null,
    timeoutTime: null,
    init: null
  }
) {
  panel.setIdForObjectWithAcronym(
    arr,
    id,
    {
      type: 'text',
      text: item.acronym ?? item.name ?? '',
      color: _options.color ?? (item.color ? timelinkStoresService.generateForegroundColor(item.color) : null) ?? null,
      bgColor: _options.bgColor ?? item.color ?? null,
      init: (panelItem) => {
        // panel.getImageFor(item, panelItem)
        let watcher = null
        watcher = (project) => {
          return watch(project, () => {
            timelinkStoresService.removeWatcher('panel', panel.getHandlerName(panelItem))
            panel.updateIdForObjectWithAcronym(arr, id, {
              text: useProjectsStore().getDisplayName(item.id),
              color: _options.color ?? (item.color ? timelinkStoresService.generateForegroundColor(item.color) : null) ?? null,
              bgColor: _options.bgColor ?? item.color ?? null,
            })
            timelinkStoresService.setOrRenewWatcher(
              'panel',
              panel.getHandlerName(panelItem),
              watcher(item)
            )
          })
        }
        timelinkStoresService.setOrRenewWatcher(
          'panel',
          panel.getHandlerName(panelItem),
          watcher(item)
        )
      },
      callback: () => {
        return () => {
          const activeTimeEntry = useTimeEntryStore().getActiveTimeEntry
          if (activeTimeEntry) {
            activeTimeEntry.project_id = item.id
            activeTimeEntry.client_id = item.client_id
            timelinkStoresService.updateTl(activeTimeEntry)
            useTimeEntryStore().delayUpdateId(activeTimeEntry.id, 1000, true)
            if (usePanelStore().wizardFinished) {
              usePanelStore().updateView('recording')
            } else {
              usePanelStore().updateView('selectService')
            }
          }
        }
      }
    },
    item
  )
}

/**
 *
 * @param {panelService} panel
 * @param {*} arr
 * @param {*} id
 * @param {*} item
 * @param {?{
 *  color: ?string,
 *  bgColor: ?string,
 *  deckColor: ?string,
 *  deckBgColor: ?string,
 *  callback: panelCallback,
 *  interval: panelCallback,
 *  intervalTime: ?number,
 *  timeout: panelCallback,
 *  timeoutTime: ?number,
 *  init: panelCallback
 * }} _options
 */
function setActiveProject(
  panel,
  arr,
  id,
  activeTimeEntry = {
    project_id: null,
    client_id: null
  },
  // eslint-disable-next-line no-unused-vars
  _options = {
    callback: null,
    interval: null,
    intervalTime: null,
    timeout: null,
    timeoutTime: null,
    init: null
  }
) {
  panel.setIdForObjectWithAcronym(
    arr,
    id,
    {
      type: 'image',
      text: useProjectsStore().getDisplayName(activeTimeEntry.project_id),
      color: _options.color ?? (useProjectsStore().getId(activeTimeEntry.project_id).color ? timelinkStoresService.generateForegroundColor(useProjectsStore().getId(activeTimeEntry.project_id).color) : null) ?? null,
      bgColor: _options.bgColor ?? useProjectsStore().getId(activeTimeEntry.project_id).color ?? null,
      // color: '#fff',
      // bgColor: '#FC9854',
      init: (item) => {
        let watcher = null
        watcher = (timeEntry) => {
          let timeWatch = watch(timeEntry, async () => {
            timelinkStoresService.removeWatcher('panel', panel.getHandlerName(item))
            const activeTimeEntry = useTimeEntryStore().getActiveTimeEntry

            if (!activeTimeEntry) {
              timelinkStoresService.removeWatcher('panel', panel.getHandlerName(item))
              return
            }
            if (activeTimeEntry.project_id) {
              panel.updateIdForObjectWithAcronym(
                arr,
                id,
                {
                  text: useProjectsStore().getDisplayName(activeTimeEntry.project_id),
                  color: '#fff',
                  bgColor: '#FC9854'
                },
                useProjectsStore().getId(activeTimeEntry.project_id)
              )
            } else {
              await nextTick()
              if (
                usePanelStore().view != 'selectProject' &&
                useTimeEntryStore().requiredFields.includes('project_id')
              ) {
                usePanelStore().updateView('selectClient', null)
              } else {
                panel.updateId(arr, id, {
                  text: 'Kein Projekt',
                  type: 'text',
                  color: null,
                  bgColor: null,
                  image: null
                })
              }
            }
            timelinkStoresService.setOrRenewWatcher(
              'panel',
              panel.getHandlerName(item),
              watcher(activeTimeEntry)
            )
          })
          let project = useProjectsStore().getId(activeTimeEntry.project_id)
          let projectWatch = project
            ? watch(project, () => {
              timelinkStoresService.removeWatcher('panel', panel.getHandlerName(item))
              panel.updateIdForObjectWithAcronym(
                arr,
                id,
                {
                  text: useProjectsStore().getDisplayName(activeTimeEntry.project_id),
                  color: _options.color ?? (useProjectsStore().getId(activeTimeEntry.project_id).color ? timelinkStoresService.generateForegroundColor(useProjectsStore().getId(activeTimeEntry.project_id).color) : null) ?? null,
                  bgColor: _options.bgColor ?? useProjectsStore().getId(activeTimeEntry.project_id).color ?? null,
                  // color: '#fff',
                  // bgColor: '#FC9854'
                },
                useProjectsStore().getId(activeTimeEntry.project_id)
              )
              timelinkStoresService.setOrRenewWatcher(
                'panel',
                panel.getHandlerName(item),
                watcher(activeTimeEntry)
              )
            })
            : () => {
              //
            }
          return () => {
            timeWatch()
            projectWatch()
          }
        }
        timelinkStoresService.setOrRenewWatcher(
          'panel',
          panel.getHandlerName(item),
          watcher(activeTimeEntry)
        )
      },
      callback: () => {
        return () => {
          usePanelStore().updateView('selectProject', null)
        }
      }
    },
    useProjectsStore().getId(activeTimeEntry.project_id)
  )
}

export { setProject, setActiveProject, setSelectableProject }
