<template>
  <form @submit.prevent="save" ref="form">
    <BaseModal v-model="showModal" @close-modal="closeModal" v-model:loading="isLoading">
      <template v-slot:header> {{ $t('create_project') }} </template>
      <div class="px-4 py-6 sm:p-8">
        <div class="grid max-w-2xl grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
          <CPInputs v-model="project" :validation-errors="validationErrors" :can-edit="canEdit"></CPInputs>
        </div>
      </div>
      <template v-slot:footer>
        <div class="flex items-center justify-end w-full space-x-4">
          <BaseButton class="button-gray" @click="closeModal" type="button">{{
            $t('cancel') }}
          </BaseButton>

          <BaseButton class="button-mossgray" type="submit" @click="save" :disabled="isLoading">
            {{ $t('create') }}
          </BaseButton>
        </div>
      </template>
    </BaseModal>
  </form>
</template>

<script setup>
import BaseButton from '@/components/general/BaseButton.vue'
import BaseModal from '@/components/modals/BaseModal.vue'
import CPInputs from '@/components/settings/component/CPInputs.vue'
import apiService from '@/services/api.service'
import { useCompanyStore } from '@/stores/company'
import { useAuthUserStore } from '@/stores/auth-user'
import { computed, nextTick, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { $t } from '@/config/i18n'
import { useAlertsStore } from '@/stores/alerts'
import { captureException } from '@sentry/vue'
import timelinkStoresService from '@/services/timelink-stores.service'
import { useKeySave } from '@/composables/keySave'

const companyStore = useCompanyStore()
const authUserStore = useAuthUserStore()
const alertsStore = useAlertsStore()
const route = useRoute()
const isLoading = ref(false)

const props = defineProps(['modelValue'])
const emit = defineEmits(['update:modelValue'])

const showModal = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value)
})

function closeModal() {
  showModal.value = false
  project.value = {
    name: null,
    info: null,
    active: true,
    billable: true,
    color: timelinkStoresService.getRandomColorFromPreset(),
  }
}

// TODO: Add validation errors
const errors = ref({})
const project = ref({
  name: null,
  info: null,
  active: true,
  billable: true,
  color: timelinkStoresService.getRandomColorFromPreset(),
})
const validationErrors = ref([])
const canEdit = ref(true)

const saveOnKeyDown = useKeySave(() => {
  if (!showModal.value) {
    return
  }
  if (isLoading.value) {
    return
  }
  save()
})

async function save() {
  if (companyStore.company.pull_provider || authUserStore.user.admin < 5) {
    alertsStore.error($t('errors.no_permissions'))
    return
  }
  isLoading.value = true
  try {
    let response = await apiService.create(import.meta.env.VITE_API_URL + '/api/v1/projects', {
      client_id: route.params.client_id,
      ...project.value
    })
    if (response.success) {
      showModal.value = false
      alertsStore.success($t('successfully.created_project', { projectName: project.value.name }))
      project.value = {
        name: null,
        info: null,
        active: true,
        billable: true,
        color: '#183F6A'
      }
    } else {
      alertsStore.error($t('errors.ups_create'))
    }
  } catch (error) {
    if (
      apiService.checkErrorAndNotify(error)
    ) {
      //
    }
    else if ((validationErrors.value = apiService.checkForValidationErrorAndConvert(error))) {
      //
    }
    else {
      console.error(error)
      captureException(error)
    }
  }
  isLoading.value = false
}
</script>
