<template>
  <form @submit.prevent="save" ref="form">
    <BaseModal v-model="showModal" @close-modal="closeModal" v-model:loading="isLoading">
      <template v-slot:header> {{ $t('create_client') }} </template>
      <div class="px-4 py-6 sm:p-8">
        <div class="grid max-w-2xl grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
          <CPInputs v-model="client" :validation-errors="validationErrors" :can-edit="canEdit"></CPInputs>
        </div>
      </div>

      <template v-slot:footer>
        <div class="flex items-center justify-end w-full space-x-4">
          <BaseButton class="button-gray" @click="closeModal" type="button">{{
            $t('cancel')
          }}</BaseButton>

          <BaseButton class="button-mossgray" type="submit" @click="save" :disabled="isLoading">{{ $t('create') }}
          </BaseButton>
        </div>
      </template>
    </BaseModal>
  </form>
</template>

<script setup>
import BaseButton from '@/components/general/BaseButton.vue'
import BaseModal from '@/components/modals/BaseModal.vue'
import CPInputs from './component/CPInputs.vue'
import apiService from '@/services/api.service'
import { useCompanyStore } from '@/stores/company'
import { useAuthUserStore } from '@/stores/auth-user'
import { useAlertsStore } from '@/stores/alerts'
import { captureException } from '@sentry/vue'
import { useClientsStore } from '@/stores/clients'
import { nextTick, onMounted, ref, watch } from 'vue'
import { $t } from '@/config/i18n'
import timelinkStoresService from '@/services/timelink-stores.service'
import { useKeySave } from '@/composables/keySave'

const companyStore = useCompanyStore()
const authUserStore = useAuthUserStore()
const alertsStore = useAlertsStore()
const clientsStore = useClientsStore()

const showModal = defineModel({
  default: false
})


const createModal = ref(false)
const client = ref({
  name: null,
  info: null,
  active: true,
  billable: true,
  color: timelinkStoresService.getRandomColorFromPreset(),
  acronym: null
})
const isLoading = ref(false)
const canEdit = ref(true)
const errors = ref([])
const validationErrors = ref([])

const saveOnKeyDown = useKeySave(() => {
  if (!showModal.value) {
    return
  }
  if (isLoading.value) {
    return
  }
  save()
})
async function save() {
  if (companyStore.company.pull_provider || authUserStore.user.admin < 5) {
    alertsStore.error($t('errors.no_permissions'))
    return
  }
  isLoading.value = true
  validationErrors.value = []
  try {
    let response = await apiService.create(
      import.meta.env.VITE_API_URL + '/api/v1/clients',
      client.value
    )
    if (response.success) {
      showModal.value = false
      alertsStore.success($t('clients.created', { clientName: client.value.name }))
      clientsStore.addOrUpdate(response.data)
      client.value = {
        name: null,
        info: null,
        active: true,
        billable: true,
        color: timelinkStoresService.getRandomColorFromPreset(),
        acronym: null
      }
      showModal.value = false
    } else {
      alertsStore.error($t('errors.save_returned_success_false'))
    }
  } catch (error) {
    if (apiService.checkErrorAndNotify(error)) {
      //
    }
    else if ((validationErrors.value = apiService.checkForValidationErrorAndConvert(error))) {
      //
    }
    else {
      alertsStore.error($t('errors.ups'))
      captureException(error)
    }
  }
  isLoading.value = false
}
function isEditable(field) {
  // let provider = companyStore.company.pull_provider
  if (field == 'acronym') {
    return true
  }
  if (field == 'color') {
    return true
  }
  return false
}
function closeModal() {
  isLoading.value = false
  showModal.value = false
  client.value = {
    name: null,
    info: null,
    active: true,
    billable: true,
    acronym: null,
    color: timelinkStoresService.getRandomColorFromPreset(),
  }
}


</script>
