<template>
    <BaseModal v-model="showModal" @close-modal="showModal = false">
        <template #header>
            <h1 class="inline-block text-lg font-bold">
                <span>{{
                    $t('choose_provider_or_master_data.header') }}</span>
            </h1>
        </template>
        <FirstStepsContent @clicked-box="showModal = false">
        </FirstStepsContent>
        <template #footer>
            <div class="flex items-center justify-end w-full">
                <BaseButton @click="showModal = false" class="button-mossgray">
                    {{ $t('choose_provider_or_master_data.button') }}
                </BaseButton>
            </div>
        </template>

    </BaseModal>
</template>

<script setup>
import { ref, watch } from 'vue';
import BaseModal from './BaseModal.vue';
import FirstStepsContent from './content/FirstStepsContent.vue';
import BaseButton from '../general/BaseButton.vue';
import { $t } from '@/config/i18n';

const showModal = defineModel({
    default: false,
    type: Boolean
})
// watch(() => showModal.value, (newVal) => {
//     console.log(newVal)
// })
</script>