<template>
    <BaseModal v-model="showModal" @close-modal="closeModal" :hide-close-button="true">
        <LoadingSpinner v-model="isLoading"></LoadingSpinner>
        <template #header>
            <h1 class="inline-block text-lg font-bold">
                <span>Willkommen bei timelink</span>
            </h1>
        </template>

        <template v-if="companyStore.company.force_oauth">
            <div class="flex flex-col gap-4 px-4 py-6 sm:p-8">
                <p>
                    {{ $t('invitation.modal.force_oauth', { domain: domain }) }}
                </p>
                <p>
                    {{ $t('invitation.modal.help_center') }}
                </p>
            </div>
        </template>
        <template v-else>
            <div class="flex flex-col gap-4 px-4 py-6 sm:p-8">
                <p>
                    {{ $t('invitation.modal.input_password') }}
                </p>
                <p>
                    {{ $t('invitation.modal.help_center') }}
                </p>
            </div>

            <form @submit.prevent="save">
                <div class="px-4 pb-6 sm:p-8 sm:pt-0">
                    <div class="grid max-w-2xl grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
                        <div class="sm:col-span-3">
                            <BaseInput :validation-errors="validationErrors.password" type="password" name="password"
                                id="password" autocomplete="new-password" v-model="password">
                                {{ $t('password') }}
                            </BaseInput>
                        </div>

                        <div class="sm:col-span-3">
                            <BaseInput :validation-errors="validationErrors.password_confirmation" type="password"
                                name="password_confirmation" id="password_confirmation" autocomplete="new-password"
                                v-model="password_confirmation">
                                {{ $t('password_confirmation') }}
                            </BaseInput>
                        </div>
                    </div>
                </div>
            </form>
        </template>


        <template #footer>
            <div class="flex items-center justify-end w-full">
                <BaseButton @click="save" class="button-mossgray">
                    <span v-if="companyStore.company.force_oauth">
                        {{ $t('invitation.modal.start_now') }}
                    </span>
                    <span v-else>
                        {{ $t('save') }}
                    </span>
                </BaseButton>
            </div>
        </template>

    </BaseModal>
    <!-- TODO: In eine Komponente ziehen. -->
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import BaseModal from './BaseModal.vue';
import FirstStepsContent from './content/FirstStepsContent.vue';
import BaseButton from '../general/BaseButton.vue';
import { $t } from '@/config/i18n';
import BaseInput from '../general/BaseInput.vue';
import LoadingSpinner from '../LoadingSpinner.vue';
import apiService from '@/services/api.service';
import { useAuthUserStore } from '@/stores/auth-user';
import { captureException } from '@sentry/vue';
import { useCompanyStore } from '@/stores/company';
import { useAlertsStore } from '@/stores/alerts';

const authUserStore = useAuthUserStore()
const companyStore = useCompanyStore()
const alertsStore = useAlertsStore()

const showModal = defineModel({
    default: false,
    type: Boolean
})
const isLoading = ref(false)

const domain = computed(() => {
    return authUserStore.user.email.split('@')[1] ?? authUserStore.user.email
})

const password = ref(null)
const password_confirmation = ref(null)
const validationErrors = ref([])
// watch(() => showModal.value, (newVal) => {
//     console.log(newVal)
// })

async function save() {
    if (companyStore.company.force_oauth && (authUserStore.user.invitation_incomplete ?? false) == false) {
        authUserStore.showInvitationScreen = false
        showModal.value = false
        return
    }

    if (!companyStore.company.oauth_provider && password.value == null) {
        validationErrors.value = { password: ['Sie müssen ein Password vergeben.'] }
        return
    }
    isLoading.value = true
    try {
        authUserStore.user.tl.isDirty = false
        const data = await authUserStore.updateUserData({ password: password.value, password_confirmation: password_confirmation.value })
        authUserStore.user.invitation_incomplete = undefined
        authUserStore.showInvitationScreen = false
        alertsStore.successfullySaved(10)
        // console.log(data)
    }
    catch (error) {
        if (apiService.checkErrorAndNotify(error)) {
            //
        }
        else if ((validationErrors.value = apiService.checkForValidationErrorAndConvert(error))) {
            //
        }
        else {
            console.error(error)
            captureException(error)
        }
    }
    isLoading.value = false
}

function closeModal() {
    // check if all conditions are meet that the user can hide the modal
    if (companyStore.company.force_oauth && (authUserStore.user.invitation_incomplete ?? false) == false) {
        authUserStore.showInvitationScreen = false
        showModal.value = false
    }
    if ((authUserStore.user.invitation_incomplete ?? false) == false) {
        authUserStore.showInvitationScreen = false
        showModal.value = false
    }
    return
    // reset assigned variables which are forcing the modal to stay open and close modal

}
</script>