import authService from '@/services/auth.service'
// import { useLocalStorage } from '@vueuse/core'
import axios from 'axios'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { useTimeEntryStore } from './timeEntry'
import { useClientsStore } from './clients'
import { useProjectsStore } from './projects'
import { useServicesStore } from './services'
import { useAppStore } from './app'
import timelinkStoresService from '@/services/timelink-stores.service'
import { useApiStore } from './api'
import { usePanelStore } from './panel'
import { useCompanyStore } from './company'
import { captureException, close } from '@sentry/vue'
import apiService from '@/services/api.service'
import desktopService from '@/services/desktop.service'

export const useAuthUserStore = defineStore('auth/user', {
  /**
   *
   * @returns {{
   * firstName: string,
   * lastName: string,
   * user: {
   *  id: string,
   *  email: string,
   *  admin: number,
   *  created_at: string,
   *  updated_at: string,
   *  language: ?string,
   *  timezone: ?string,
   *  invitation_incomplete: ?boolean, 
   *  settings: ?{
   *    hideWeekDays: ?Array<number>,
   *    showTimeInterval: ?Array<number>,
   *    autoLockPanel: ?Boolean,
   *    automaticStopOfRecording: ?string,
   *    notifications: ?{
   *      doBreak: ?{active: boolean, time: string},
   *      remindActiveTracking: ?{active: boolean, after: 60},
   *      onTrackingStart: {active: boolean},
   *      toStartTracking: {
   *        active: boolean,
   *        after: number,
   *        repeatAfter: number,
   *        between: {
   *          start: string,
   *          end: string
   *        },
   *        weekDays: number[]
   *      }
   *    }
   *  },
   *  tl: {
   *    isDirty: boolean,
   *    origin: object
   *  }
   * },
   * showInvitationScreen: boolean}}
   */
  state: () => {
    return {
      firstName: '',
      lastName: '',
      user: null,
      userId: null,
      token: null,
      salt: null,
      companyId: null,
      last_used: null,
      showInvitationScreen: false
    }
  },
  persist: true,

  getters: {
    fullName: (state) => `${state.user.first_name} ${state.user.last_name}`,
    isAuthenticated: (state) =>
      (state.user?.id ?? null) != null && (state.user?.id ?? null) != undefined,
    lastUsed: (state) => {
      /**
       * @returns {string[]}
       */
      return (key) => {
        let last_used = state.user?.last_used
        if (last_used == undefined || last_used == null) {
          return []
        }
        let item = last_used[key]
        if (item == undefined || item == null) {
          return []
        }
        return item
      }
    }
  },
  actions: {
    async fetch() {
      if (this.user && this.token) {
        try {
          let data = await apiService.fetch(import.meta.env.VITE_API_URL + '/api/v1/me')
          if (!data?.user) {
            timelinkStoresService.setOrRenewTimeout(
              'authUser',
              'refetch',
              () => {
                this.fetch()
              },
              5000
            )
            return
          }
          this.internalUpdateUserData(data.user)
          useCompanyStore().internalUpdate(data.company)
          this.companyId = data.companyId
          this.userId = data.userId
        } catch (error) {
          captureException(error)
        }
      }
    },
    async checkLoginStatus(token = null) {
      let authToken = token ?? this.token
      if (!authToken) {
        return false
      }
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + authToken
      window.echo.connector.options.auth.headers.Authorization =
        axios.defaults.headers.common['Authorization']

      try {
        let resp = await authService.checkLoginStatus()
        if (resp.status) {
          let user = resp.data
          user.token = user?.token ?? token ?? null
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.token
          window.echo.connector.options.auth.headers.Authorization =
            axios.defaults.headers.common['Authorization']

          this.loginSuccess(user)
          return true
        } else {
          if (resp.status == false && resp.error == false) {
            return false
          } else {
            return null
          }
        }
      } catch (error) {
        captureException(error)
        return null
      }
    },
    async loggedIn(user) {
      try {
        let data = await authService.login(user)
        // let data = request.data
        this.loginSuccess(data, true)
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token
        window.echo.connector.options.auth.headers.Authorization =
          axios.defaults.headers.common['Authorization']

        return true
      } catch (error) {
        this.loginFailure()

        axios.defaults.headers.common['Authorization'] = null
        window.echo.connector.options.auth.headers.Authorization =
          axios.defaults.headers.common['Authorization']
        throw error
      }
      // return await authService
      //   .login(user)
      //   .then((data) => {
      //     this.loginSuccess(data)
      //     axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token
      //     window.echo.connector.options.auth.headers.Authorization =
      //       axios.defaults.headers.common['Authorization']

      //     return Promise.resolve(data.user)
      //   })
      //   .catch((error) => {
      //     this.loginFailure()

      //     axios.defaults.headers.common['Authorization'] = null
      //     window.echo.connector.options.auth.headers.Authorization =
      //       axios.defaults.headers.common['Authorization']

      //     return Promise.reject(error)
      //   })
    },
    async logout(withRequest = false) {
      let success = true
      if (withRequest) {
        success = await axios
          .post(import.meta.env.VITE_API_URL + '/api/v1/logout', [])
          .then((response) => {
            if (response.status == 200 && response.data.success) {
              return true
            }
            return false
          })
          .catch(() => {
            return false
          })
      }
      if (success) {
        try {
          axios.defaults.headers.common['Authorization'] = null
          window.echo.connector.options.auth.headers.Authorization =
            axios.defaults.headers.common['Authorization']
          desktopService.setLoggedOut()
          this.$reset()
          this.token = null
          this.userId = null
          this.salt = Math.random().toString(20).substr(2, 6)
          close()
          this.$persist()
          useTimeEntryStore().$reset()
          useClientsStore().$reset()
          useProjectsStore().$reset()
          useServicesStore().$reset()
          useApiStore().$reset()
          useAppStore().$reset()
          usePanelStore().$reset()
          useCompanyStore().$reset()
          localStorage.clear()
          window.location = window.location.origin + '/login'
        } catch (error) {
          captureException(error)
          return false
        }
      }
      return success
    },
    loginSuccess(data, login = false) {
      desktopService.setLoggedOut()

      this.firstName = data.user.first_name
      this.lastName = data.user.last_name
      this.token = data.token ?? null
      this.userId = data.user.id
      this.companyId = data.user.company_id
      if (data.user.company || data.company) {
        let compData = data.user.company ? data.user.company : data.company ? data.company : null
        if (compData) {
          useCompanyStore().internalUpdate(compData)
        }
        try {
          delete data.user.company
        } catch (error) {
          //
        }
      }
      this.user = data.user
      this.salt = null
      this.user.tl = {
        isDirty: false,
        origin: { ...data.user },
        ignoreKeys: []
      }
      // console.log(this.user.invitation_incomplete, login)
      if (login) {
        this.checkInvitationStatus()
      }
    },
    loginFailure() {
      this.firstName = ''
      this.lastName = ''
      this.userId = null
      this.companyId = null
      this.user = null
      this.token = null
    },

    checkInvitationStatus() {
      if ((this.user?.invitation_incomplete ?? false)) {
        this.showInvitationScreen = true
        // console.log(this.showInvitationScreen)
      }
    },

    async fetchUpdate() {
      try {
        let resp = await authService.checkLoginStatus()
        if (resp.status) {
          this.internalUpdateUserData(resp.data?.user?.user)
        }
      } catch (error) {
        console.error(error)
        return
      }
    },
    updateLastUsed(type, entry) {
      if (!entry) {
        return
      }
      if (!this.user.last_used || Array.isArray(this.user.last_used) || typeof this.user.last_used != 'object') {
        this.user.last_used = {}
      }
      let entries = this.user.last_used[type] ?? []
      entries = entries.filter((item) => item != entry)
      entries.unshift(entry)
      this.user.last_used[type] = entries
    },
    async updateUserData(passwords = null, language = null) {
      if (passwords) {
        if (
          passwords.password != undefined &&
          passwords.password != null &&
          passwords.password != passwords.password_confirmation
        ) {
          return Promise.reject()
        }
      } else {
        passwords = {}
      }
      return await axios
        .patch(import.meta.env.VITE_API_URL + '/api/v1/users/' + this.userId, {
          // TODO: add more fields
          settings: this.user.settings,
          first_name: this.user.first_name,
          last_name: this.user.last_name,
          ...passwords,
          language: language ?? this.user.language ?? 'de'
        })
        .then((response) => {
          this.internalUpdateUserData(response.data.data)
          return Promise.resolve(response.data.data)
        })
        .catch((response) => {
          return Promise.reject(response)
        })
    },
    internalUpdateUserData(data) {
      if (!data) {
        return
      }
      if (data.company || data.company) {
        let compData = data.company ? data.company : null
        if (compData) {
          useCompanyStore().internalUpdate(compData)
        }
        try {
          delete data.company
        } catch (error) {
          captureException(error)
        }
      }
      if (!this.user && !this.token) {
        return
      }
      if (this.user.tl == undefined) {
        this.user.tl = {
          isDirty: false,
          origin: { ...data },
          last_fetch: Date.now()
        }
      } else {
        this.user.tl.origin = data
        this.user.tl.last_fetch = Date.now()
      }
      if (this.user.updated_at != data.updated_at) {
        Object.entries(data).forEach((item) => {
          this.user[item[0]] = item[1]
        })
      }
      try {
        delete this.user.company
      } catch (error) {
        //
      }
      timelinkStoresService.updateTl(this.user)
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthUserStore, import.meta.hot))
}
