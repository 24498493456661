import { usePanelStore } from '@/stores/panel'
import { useTimeEntryStore } from '@/stores/timeEntry'
import timelinkStoresService from '@/services/timelink-stores.service'

// eslint-disable-next-line no-unused-vars
import panelService from '@/services/panel.service'
import { nextTick, watch } from 'vue'
import { useServicesStore } from '@/stores/services'

/**
 *
 * @param {panelService} panel
 * @param {*} arr
 * @param {*} id
 * @param {*} item
 * @param {*} _options
 */
function setSelectableService(
  panel,
  arr,
  id,
  item,
  // eslint-disable-next-line no-unused-vars
  _options = {
    callback: null,
    interval: null,
    intervalTime: null,
    timeout: null,
    timeoutTime: null,
    init: null
  }
) {
  panel.setIdWithImage(arr, id, {
    type: 'text',
    text: item.acronym ?? item.name ?? '',
    color: _options.color ?? (item.color ? timelinkStoresService.generateForegroundColor(item.color) : null) ?? null,
    bgColor: _options.bgColor ?? item.color ?? null,
    init: (panelItem) => {
      panel.getImageFor(item, panelItem)
      let watcher = null
      watcher = (service) => {
        return watch(service, () => {
          timelinkStoresService.removeWatcher('panel', panel.getHandlerName(panelItem))
          panel.updateIdWithImage(arr, id, {
            text: item.acronym ?? item.name ?? '',
            color: _options.color ?? (item.color ? timelinkStoresService.generateForegroundColor(item.color) : null) ?? null,
            bgColor: _options.bgColor ?? item.color ?? null,
          }, item)
          timelinkStoresService.setOrRenewWatcher(
            'panel',
            panel.getHandlerName(panelItem),
            watcher(item)
          )
        })
      }
      timelinkStoresService.setOrRenewWatcher(
        'panel',
        panel.getHandlerName(panelItem),
        watcher(item)
      )
    },
    callback: () => {
      return () => {
        const activeTimeEntry = useTimeEntryStore().getActiveTimeEntry
        if (activeTimeEntry) {
          activeTimeEntry.service_id = item.id
          timelinkStoresService.updateTl(activeTimeEntry)
          useTimeEntryStore().delayUpdateId(activeTimeEntry?.id, 1000, true)
          usePanelStore().updateView('recording', null, true)
        }
      }
    }
  }, item)
}

/**
 *
 * @param {panelService} panel
 * @param {*} arr
 * @param {*} id
 * @param {*} activeTimeEntry
 */
function setActiveService(
  panel,
  arr,
  id,
  activeTimeEntry = {
    service_id: null,
    client_id: null
  },
  // eslint-disable-next-line no-unused-vars
  _options = {
    callback: null,
    interval: null,
    intervalTime: null,
    timeout: null,
    timeoutTime: null,
    init: null
  }
) {
  panel.setIdWithImage(
    arr,
    id,
    {
      type: 'image',
      text: useServicesStore().getDisplayName(activeTimeEntry.service_id),
      color: _options.color ?? (useServicesStore().getId(activeTimeEntry.service_id).color ? timelinkStoresService.generateForegroundColor(useServicesStore().getId(activeTimeEntry.service_id).color) : null) ?? null,
      bgColor: _options.bgColor ?? useServicesStore().getId(activeTimeEntry.service_id).color ?? null,
      // color: '#fff',
      // bgColor: '#FC9854',
      init: (item) => {
        let watcher = null
        watcher = (timeEntry) => {
          let timeWatch = watch(timeEntry, async () => {
            timelinkStoresService.removeWatcher('panel', panel.getHandlerName(item))
            const activeTimeEntry = useTimeEntryStore().getActiveTimeEntry
            if (!activeTimeEntry) {
              timelinkStoresService.removeWatcher('panel', panel.getHandlerName(item))
              return
            }

            if (activeTimeEntry.service_id) {
              panel.updateIdWithImage(
                arr,
                id,
                {
                  text: useServicesStore().getDisplayName(activeTimeEntry.service_id),
                  color: _options.color ?? (useServicesStore().getId(activeTimeEntry.service_id).color ? timelinkStoresService.generateForegroundColor(useServicesStore().getId(activeTimeEntry.service_id).color) : null) ?? null,
                  bgColor: _options.bgColor ?? useServicesStore().getId(activeTimeEntry.service_id).color ?? null,
                },
                useServicesStore().getId(activeTimeEntry.service_id)
              )
            } else {
              await nextTick()
              if (usePanelStore().view != 'selectService') {
                usePanelStore().updateView('selectService', null)
              }
            }
            timelinkStoresService.setOrRenewWatcher(
              'panel',
              panel.getHandlerName(item),
              watcher(activeTimeEntry)
            )
          })
          let service = useServicesStore().getId(activeTimeEntry.service_id)
          let serviceWatch = service
            ? watch(service, () => {
              timelinkStoresService.removeWatcher('panel', panel.getHandlerName(item))
              panel.updateIdWithImage(
                arr,
                id,
                {
                  text: useServicesStore().getDisplayName(activeTimeEntry.service_id),
                  color: _options.color ?? (useServicesStore().getId(activeTimeEntry.service_id).color ? timelinkStoresService.generateForegroundColor(useServicesStore().getId(activeTimeEntry.service_id).color) : null) ?? null,
                  bgColor: _options.bgColor ?? useServicesStore().getId(activeTimeEntry.service_id).color ?? null,
                },
                useServicesStore().getId(activeTimeEntry.service_id)
              )
              timelinkStoresService.setOrRenewWatcher(
                'panel',
                panel.getHandlerName(item),
                watcher(activeTimeEntry)
              )
            })
            : () => {
              //
            }
          return () => {
            timeWatch()
            serviceWatch()
          }
        }
        timelinkStoresService.setOrRenewWatcher(
          'panel',
          panel.getHandlerName(item),
          watcher(activeTimeEntry)
        )
      },
      callback: () => {
        return () => {
          usePanelStore().updateView('selectService', null)
        }
      }
    },
    useServicesStore().getId(activeTimeEntry.service_id)
  )
}

export { setActiveService, setSelectableService }
