import { usePanelStore } from '@/stores/panel'
import { useTimeEntryStore } from '@/stores/timeEntry'
import { useClientsStore } from '@/stores/clients'
import deckService from '@/services/deck.service'

import { findIconDefinition } from '@fortawesome/fontawesome-svg-core'
import { nextTick, toRaw, watch } from 'vue'
import { useCompanyStore } from '@/stores/company'
import { $t } from '@/config/i18n'
// eslint-disable-next-line no-unused-vars
import panelService from '@/services/panel.service'

import { setSelectableClient } from '@/panel/helper/clients'
import { setActiveProject } from '@/panel/helper/projects'
import { setActiveService } from '@/panel/helper/services'
import timelinkStoresService from '@/services/timelink-stores.service'

/**
 *
 * @param {panelService} panel
 * @param {*} arr
 * @param {*} page
 */
function viewClients(panel, arr, page = null) {
  const activeTimeEntry = useTimeEntryStore().getActiveTimeEntry
  let pagination = null
  let clients = []
  const dims = deckService.getDimensions()
  let column = 0
  let row = 1
  let id = column + row * dims.columns
  panel.setId(arr, 1, {
    type: 'text',
    text: $t('panel.customer_selection'),
    isAcronym: false,
    image: null
  })
  panel.setId(
    arr,
    0,
    !activeTimeEntry?.client_id || !activeTimeEntry?.service_id
      ? {
        ...panel.getCancelButton(),
        init: (item, panelArr) => {
          timelinkStoresService.setOrRenewWatcher(
            'panel',
            panel.getHandlerName(item),
            watch(
              () => activeTimeEntry.client_id,
              async (newVal) => {
                await nextTick()
                if (usePanelStore().view != 'selectClient') {
                  return
                }
                if (newVal) {
                  usePanelStore().updateView('recording')
                }
              }
            )
          )
        }
      }
      : panel.getBackButton()
  )
  if (usePanelStore().wizardFinished) {
    if (activeTimeEntry?.project_id) {
      setActiveProject(panel, arr, 2, activeTimeEntry)
    }
    if (activeTimeEntry?.service_id) {
      setActiveService(panel, arr, 3, activeTimeEntry)
    }
    let needToLoadMore = false
    if (page == null) {
      useClientsStore().fetchActiveCount()
      clients = useClientsStore().getLastUsed.filter((item) => item.active)
      row = 1
      column = 0
      id = column + row * dims.columns
      let fetch = async () => {
        useClientsStore().fetch(
          {
            active: true,
            page: 1,
            limit: dims.columns * dims.rows * 2,
            orders: [
              {
                column: 'name',
                direction: 'asc'
              }
            ],
            withLimitedPartOfProjects: true
          },
          false,
          true
        )
      }
      fetch()

      panel.setId(arr, dims.columns - 1, {
        type: 'image',
        text: $t('panel.all_clients'),
        image: panel.getIcon(findIconDefinition({ prefix: 'fa-kit', iconName: 'tl-arrow-down-az' })),
        callback: () => {
          return () => {
            usePanelStore().updatePage(0)
          }
        }
      })

      clients = useClientsStore().getLastUsed.filter((item) => item.active)
    } else {
      panel.setId(arr, dims.columns - 1, {
        type: 'image',
        text: $t('panel.last_ten_clients'),
        image: panel.getIcon(findIconDefinition({ prefix: 'fa-kit', iconName: 'tl-clock-rotate-left' })),
        callback: () => {
          return () => {
            usePanelStore().updatePage(null)
          }
        }
      })
      const count =
        (useClientsStore()?.activeCount
          ? useClientsStore()?.activeCount
          : useCompanyStore()?.company?.active_clients_count) ?? 0

      pagination = panel.getPagination(row, column, dims.rows, dims.columns, count, page)
      let lastChange = toRaw(usePanelStore().lastChange)
      clients = useClientsStore()
        .clients.filter((item) => item.active)
        .toSorted((a, b) => a.name.localeCompare(b.name))
      let tempCount = clients.length
      if (tempCount - pagination.end < count - pagination.end) {
        needToLoadMore = true
      } else {
        if (pagination.next) {
          panel.setId(arr, dims.rows * dims.columns - 1, panel.getPaginationNextButton(page))
        }
      }
      clients = clients.slice(pagination.start, pagination.end)
      let fetch = async (panel, arr) => {
        useClientsStore().fetch(
          {
            active: true,
            page: page + 2,
            limit: dims.columns * dims.rows * 2,
            orders: [
              {
                column: 'name',
                direction: 'asc'
              }
            ],
            withLimitedPartOfProjects: true
          },
          false,
          true,
          (data) => {
            useClientsStore().activeCount = data.meta.total
            if (usePanelStore().view != 'selectClient' || !pagination.next || !needToLoadMore) {
              return
            }
            panel.setId(arr, dims.rows * dims.columns - 1, panel.getPaginationNextButton(page))
          }
        )
      }
      fetch(panel, arr)
    }
    clients.forEach((item) => {
      if (pagination) {
        if (pagination.previous && id == 0 + (dims.rows - 1) * dims.columns) {
          id = id + 1
        }
        if (pagination.next && id == dims.rows * dims.columns - 1) {
          id = id + 1
        }
        if (id >= dims.rows * dims.columns) {
          return
        }
      }
      setSelectableClient(panel, arr, id, item)
      id = id + 1
    })
    if (pagination?.previous) {
      panel.setId(arr, 0 + (dims.rows - 1) * dims.columns, panel.getPaginationPreviousButton(page))
    }
    if (pagination?.next) {
      if (page == null || !needToLoadMore) {
        panel.setId(arr, dims.rows * dims.columns - 1, panel.getPaginationNextButton(page))
      } else {
        panel.setId(arr, dims.rows * dims.columns - 1, {
          type: 'image',
          text: $t('panel.loading_dots'),
          image: panel.getIcon(findIconDefinition({ prefix: 'fa-kit', iconName: 'tl-loader' })),
          color: '#555',
          deckColor: '#aaa'
        })
      }
    }
  }
}

export default viewClients
