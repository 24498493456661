import { useApiStore } from '@/stores/api'
import { useAuthUserStore } from '@/stores/auth-user'
import axios, { AxiosError } from 'axios'
import timelinkStoresService from '@/services/timelink-stores.service'
import logger from './logger.service'
import { captureException, close as SentryClose } from '@sentry/vue'
import { $t } from '@/config/i18n'
import { useAlertsStore } from '@/stores/alerts'

class ApiService {
  static signal = null
  static counterRequests = 0
  static requestSignal = null

  limits = [10, 25, 50, 100, 200, 500]

  async fetch(url, params = {}, callableSuccess = null, callableError = null) {
    return await this.createGetRequest(url, params, callableSuccess, callableError)
  }

  async fetchAll(
    url,
    params,
    callableSuccess = null,
    callableError = null,
    loadAllCallbackPromise = null
  ) {
    if (!params) {
      params = {
        page: 1
      }
    }
    if (!params.page) {
      params.page = 1
    }
    if (params.page) {
      params.page = 1
    }
    await this.createGetRequest(
      url,
      params,
      callableSuccess,
      callableError,
      true,
      loadAllCallbackPromise
    )
  }

  async fetchId(url, id, params = null, callableSuccess = null, callableError = null) {
    ApiService.counterRequests++
    try {
      let response = await axios.get(url + '/' + id, { params: { ...params } })
      if (useApiStore().connectionError) {
        useApiStore().connectionError = false
      }
      this.resetSignals()

      ApiService.counterRequests--
      if (ApiService.counterRequests == 0) {
        ApiService.requestSignal = null
      }
      if (typeof callableSuccess == 'function') {
        callableSuccess(response.data, response)
      }
      return response.data
    } catch (error) {
      ApiService.counterRequests--
      logger.error('api', 'fetchId', error?.response?.status, error)
      if (this.checkIfNotAuthenticated(error)) {
        return
      }
      this.checkIfCanceled(error)
      if (typeof callableError == 'function') {
        callableError(error)
      }
      throw error
    }
  }

  async create(url, postData, options = {}, callableSuccess = null, callableError = null) {
    try {
      let response = await axios.post(url, postData, options)
      this.resetSignals()

      if (typeof callableSuccess == 'function') {
        callableSuccess(response.data, response)
      }
      return response.data
    } catch (error) {
      logger.error('api', 'create', error?.response?.status, error)
      if (this.checkIfNotAuthenticated(error)) {
        return
      }
      this.checkIfCanceled(error)
      if (typeof callableError == 'function') {
        callableError(error)
      }
      throw error
    }
  }

  async createEntry(
    type,
    url,
    internalId,
    postData,
    callableSuccess,
    callableError,
    isRetry = false
  ) {
    if (!isRetry) {
      if (useApiStore().connectionError || useApiStore().needQueue) {
        useApiStore().addIdToCreate(type, internalId)
      }
    }

    if (!ApiService.signal) {
      ApiService.signal = new AbortController()
    }

    try {
      let response = await axios.post(url, postData, {
        signal: ApiService.signal?.signal
      })
      this.resetSignals()

      //TODO: Add logic
      if (useApiStore().connectionError) {
        useApiStore().connectionError = false
      }
      this.resetSignals()
      if (response.data.data?.id) {
        if (typeof callableSuccess === 'function') {
          callableSuccess(response.data.data, response)
        }
      }

      useApiStore().removeIdFromCreate(type, internalId)
      useApiStore().checkIfQueueIsNeeded()
      return response.data
    } catch (error) {
      if (error.code == 'ERR_NETWORK') {
        if (ApiService.signal) {
          ApiService.signal.abort()
          timelinkStoresService.setOrRenewTimeout(
            'apiService',
            'terminateSignal',
            () => {
              ApiService.signal = null
            },
            1000
          )
        }
        if (!useApiStore().connectionError) {
          useApiStore().connectionError = true
          useApiStore().needQueue = true
        }
        useApiStore().addIdToCreate(type, internalId)
      }
      if (this.checkIfNotAuthenticated(error)) {
        return
      }
      this.checkIfCanceled(error)
      logger.error('api', 'createEntry', error?.response?.status, error)

      if (typeof callableError === 'function') {
        callableError(error)
      }

      throw error
    }
  }

  async update(url, id = null, postData, options, callableSuccess = null, callableError = null) {
    try {
      let response = await axios.patch(url + (id ? '/' + id : ''), postData, options)
      this.resetSignals()

      if (typeof callableSuccess == 'function') {
        callableSuccess(response.data)
      }
      return response.data
    } catch (error) {
      logger.error('api', 'update', error?.response?.status, error)

      if (this.checkIfNotAuthenticated(error)) {
        return
      }
      this.checkIfCanceled(error)
      if (typeof callableError == 'function') {
        callableError(error)
      }
      throw error
    }
  }

  async updateEntry(
    type,
    url,
    id = null,
    postData = {},
    callableSuccess = null,
    callableError = null,
    isRetry = false,
    isPost = false
  ) {
    if (!isRetry) {
      if (useApiStore().connectionError || useApiStore().needQueue) {
        useApiStore().addIdToUpdate(type, id)
      }
    }

    if (!ApiService.signal) {
      ApiService.signal = new AbortController()
    }

    try {
      let response = null
      if (!isPost) {
        response = await axios.put(url + (id ? '/' + id : ''), postData, {
          signal: ApiService.signal?.signal
        })
      } else {
        response = await axios.post(url + (id ? '/' + id : ''), postData, {
          signal: ApiService.signal?.signal
        })
      }

      if (useApiStore().connectionError) {
        useApiStore().connectionError = false
      }
      this.resetSignals()

      if (response.data.data?.id) {
        if (response.data.data?.id) {
          if (typeof callableSuccess == 'function') {
            callableSuccess(response.data.data, response)
          }
        }
      }
      useApiStore().removeIdFromUpdate(type, id)
      useApiStore().checkIfQueueIsNeeded()
      return response.data
    } catch (error) {
      if (error.code == 'ERR_NETWORK') {
        if (ApiService.signal) {
          ApiService.signal.abort()
          timelinkStoresService.setOrRenewTimeout(
            'apiService',
            'terminateSignal',
            () => {
              ApiService.signal = null
            },
            1000
          )
        }
        if (!useApiStore().connectionError) {
          useApiStore().connectionError = true
          useApiStore().needQueue = true
        }
        useApiStore().addIdToUpdate(type, id)
      }
      if (this.checkIfNotAuthenticated(error)) {
        return
      }
      this.checkIfCanceled(error)
      logger.error('api', 'updateEntry', error?.response?.status, error)
      if (typeof callableError == 'function') {
        callableError(error)
      }
      throw error
    }
  }

  async delete(url, id = null, postData = null, callableSuccess = null, callableError = null) {
    let fullUrl = !id ? url : url + '/' + id
    try {
      let response = await axios.delete(fullUrl, {
        params: postData ?? null
      })
      this.resetSignals()

      if (typeof callableSuccess == 'function') {
        callableSuccess(response)
      }
      return response.data
    } catch (error) {
      logger.error('api', 'delete', error?.response?.status, error)
      if (this.checkIfNotAuthenticated(error)) {
        return
      }
      this.checkIfCanceled(error)
      if (typeof callableError == 'function') {
        callableError(error)
      }
      throw error
    }
  }

  async deleteEntry(
    type,
    url,
    id,
    postData = null,
    callableSuccess = null,
    callableError = null,
    isRetry = false
  ) {
    if (!isRetry) {
      if (useApiStore().connectionError || useApiStore().needQueue) {
        useApiStore().addIdToDelete(type, id)
      }
    }

    if (!ApiService.signal) {
      ApiService.signal = new AbortController()
    }
    try {
      let response = await axios.delete(url + '/' + id, {
        signal: ApiService.signal?.signal,
        params: postData ?? null
      })
      if (useApiStore().connectionError) {
        useApiStore().connectionError = false
      }
      this.resetSignals()

      if (response.data?.success) {
        if (typeof callableSuccess == 'function') {
          callableSuccess(response.data.data, response)
        }
      }
      useApiStore().removeIdFromDelete(type, id)
      useApiStore().checkIfQueueIsNeeded()
      return response.data
    } catch (error) {
      console.log(error)
      if (
        error?.code == 'ERR_BAD_REQUEST' &&
        error?.response?.status == 404 &&
        error?.response?.request?.responseURL == url + '/' + id
      ) {
        if (typeof callableSuccess == 'function') {
          callableSuccess({ success: true, id: id }, { error })
        }
        return { success: true, id: id }
      }
      if (error?.code == 'ERR_NETWORK') {
        if (ApiService.signal) {
          ApiService.signal.abort()
          timelinkStoresService.setOrRenewTimeout(
            'apiService',
            'terminateSignal',
            () => {
              ApiService.signal = null
            },
            1000
          )
        }
        if (!useApiStore().connectionError) {
          useApiStore().connectionError = true
          useApiStore().needQueue = true
        }
        useApiStore().addIdToDelete(type, id)
      }
      this.checkIfCanceled(error)
      if (this.checkIfNotAuthenticated(error)) {
        return
      }
      if (typeof callableError == 'function') {
        callableError(error)
      }
      logger.error('api', 'deleteEntry', error?.response?.status, error)
      throw error
    }
  }

  async createGetRequest(
    url,
    params,
    callableSuccess = null,
    callableError = null,
    loadAll = false,
    loadAllCallbackPromise = null
  ) {
    ApiService.counterRequests++
    if (!ApiService.requestSignal) {
      ApiService.requestSignal = new AbortController()
      // For testing resets of aborts
      // if ((Math.random() * 2) % 2) {
      //   ApiService.requestSignal.abort()
      // }
    }
    try {
      let response = await axios.get(url, {
        signal: ApiService.requestSignal?.signal,
        params: {
          ...params
        }
      })

      if (useApiStore().connectionError) {
        useApiStore().connectionError = false
      }
      ApiService.counterRequests--
      if (ApiService.counterRequests == 0) {
        this.resetSignals()
      }
      if (typeof callableSuccess == 'function') {
        callableSuccess(response.data, response)
      }
      if (loadAll && this.loadMore(response)) {
        params.page = (params.page ?? 1) + 1
        await this.createGetRequest(
          url,
          params,
          callableSuccess,
          callableError,
          true,
          loadAllCallbackPromise
        )
      } else if (loadAll && !this.loadMore(response)) {
        if (loadAllCallbackPromise) {
          loadAllCallbackPromise()
        }
      }
      return response.data
    } catch (error) {
      ApiService.counterRequests--

      if (error?.code == 'ERR_NETWORK') {
        if (ApiService.requestSignal) {
          ApiService.requestSignal.abort()
        }
      }
      this.checkIfCanceled(error)
      if (ApiService.counterRequests == 0) {
        ApiService.requestSignal = null
      }

      if (this.checkIfNotAuthenticated(error)) {
        return
      }

      logger.error('api', 'createGetRequest', error?.response?.status, error)
      throw error
    }
  }

  loadMore(response) {
    return (
      response !== 'error' &&
      response !== 'canceled' &&
      response?.data?.meta &&
      response.data.meta?.current_page &&
      response.data.meta?.last_page &&
      response.data.meta.current_page != response.data.meta.last_page
    )
  }

  async post(url, params = {}) {
    try {
      return (await axios.post(url, params)).data
    } catch (error) {
      if (this.checkIfNotAuthenticated(error)) {
        return
      }
      throw error
    }
  }

  abortAll() {
    if (ApiService.signal) {
      ApiService.signal.abort()
    }
    if (ApiService.requestSignal) {
      ApiService.requestSignal.abort()
    }
  }

  resetSignal() {
    ApiService.signal = null
    ApiService.requestSignal = null
  }

  errorHasResponseWithStatus(error) {
    return typeof error == 'object' &&
      Object.hasOwn(error, 'response') &&
      Object.hasOwn(error.response, 'status')
  }

  checkIfNotAuthenticated(error) {
    if (
      this.errorHasResponseWithStatus(error) &&
      // error.response.status == 419 ||
      (error.response.status == 401 || error.response.data?.message == 'Unauthenticated.') &&
      (error.response?.request?.responseURL ?? '').includes(import.meta.env.VITE_API_URL)
    ) {
      const alertsStore = useAlertsStore()
      alertsStore.error($t('errors.no_auth'))
      const authUserStore = useAuthUserStore()
      authUserStore.logout()

      SentryClose()
      window.location.reload()

      return true
    }
    return false
  }

  checkIfCanceled(error) {
    if (axios.isCancel(error)) {
      if (!useApiStore().needQueue && !useApiStore().connectionError) {
        this.resetSignals()
      }
      // return 'canceled'
    }
  }

  /**
   * Used to notify the user if there was an error that also throws a notification
   * @returns void
   */
  checkErrorAndNotify(error) {
    return this.checkIfNotAuthenticated(error) || this.checkIfNoSubscriptionActive(error)
      || this.checkIfServerError(error)
  }

  checkIfServerError(error) {
    if (
      this.errorHasResponseWithStatus(error) &&
      error.response.status === 500
    ) {
      captureException(error)
      useAlertsStore().error($t('errors.500'))
      return true
    }
    return false
  }

  checkIfNoSubscriptionActive(error) {
    console.log(error)
    if (
      this.errorHasResponseWithStatus(error) &&
      error.response.status === 403 &&
      error.response.data.message == 'You are not subscribed!'
    ) {
      useAlertsStore().error($t('errors.no_subscription'))
      return true
    }
    return false
  }

  checkIfPageNotFound(error) {
    if (
      this.errorHasResponseWithStatus(error) &&
      error.response.status == 404
    ) {
      return true
    }
    return false
  }

  // checkIfPageNotFound(error) {

  // }

  checkForValidationError(error) {
    if (
      this.errorHasResponseWithStatus(error) &&
      error.response.status === 422
    ) {
      return true
    }
    return false
  }

  /**
   * 
   * @param {Array<Object.<string,Array<String>>} error 
   * @returns 
   */
  checkForValidationErrorAndConvert(error) {
    if (!this.checkForValidationError(error)) {

      return []
    }
    return this.convertValidationErrors(error)
  }

  // TODO: Change if there is an alert needed?!
  checkIfRateLimitExceeded(error) {
    if (
      this.errorHasResponseWithStatus(error) &&
      error.response.status == 429
    ) {
      return true
    }
    return false
  }

  resetSignals() {
    ApiService.requestSignal = null
    ApiService.signal = null
  }

  getRequestSignal() {
    return ApiService.requestSignal
  }

  getSignal() {
    return ApiService.signal
  }

  /**
   *
   * @param {String} str
   */
  convertValidationKey(item) {
    console.log(item)
    return item
      .substring(0, item.length - 1)
      .toLowerCase()
      .replaceAll(' ', '_')
  }

  /*
  JSON Schema validation errors

{
  "message": "The selected client id is invalid. (and 2 more errors)",
  "errors": {
    "client_id": [
      "The selected client id is invalid."
    ],
    "service_id": [
      "The selected service id is invalid."
    ]
  }
}

  */
  convertValidationErrors(response) {
    console.log(response)
    response = response.response ? response.response : response
    let validations =
      response?.data?.data?.errors ?? response?.data?.errors ?? response?.errors ?? response
    let result = {}
    Object.keys(validations).forEach((key) => {
      if (Array.isArray(validations[key])) {
        result[key] = validations[key].map((item) => $t(this.convertValidationKey(item)))
      } else {
        result[key] = [$t(this.convertValidationKey(validations[key]))]
      }
    })
    return result
  }
}

export default new ApiService()
