<!-- TODO: Translation -->
<template>
  <div class="flex flex-col items-center justify-center flex-grow w-full min-h-screen">
    <LoadingSpinner v-model="showSpinner" :fullPage="true"></LoadingSpinner>
    <div class="w-full max-w-xl space-y-8 bg-white border rounded-2xl border-mossgray-200">
      <div class="w-full p-12 pt-0 space-y-8">
        <div>
          <div class="flex justify-center px-16 py-8 mt-6 -mb-2 text-mossgray" v-if="!success">
            <img :src="img" />
          </div>
          <router-link class="flex justify-center px-16 py-8 mt-6 -mb-2 cursor-pointer text-mossgray" v-else="success"
            to="/login">
            <img :src="img" />
          </router-link>

          <h2 class="mt-6 text-3xl font-extrabold text-center">
            Registeriere Dich jetzt
          </h2>
          <h3 class="mt-0 text-lg font-bold text-center">und teste {{ trial_days }} Tage lang völlig kostenlos.</h3>
        </div>
        <div v-if="!success">
          <form class="space-y-4" @submit.prevent="sendRegistration">
            <input type="hidden" name="remember" value="true" />
            <div class="-space-y-px rounded-md shadow-sm">
              <div>
                <!-- TODO: Harmonise input -->
                <label for="email-address" class="sr-only">Email address</label>
                <input v-model="email" id="email-address" name="email" type="email" autocomplete="email" required
                  class="relative block w-full px-3 py-2 placeholder-gray-500 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-mossgray focus:border-mossgray focus:z-10 sm:text-sm"
                  placeholder="E-Mail Adresse" />
              </div>
            </div>
            <div class="flex flex-row items-center px-3 py-2 mt-4 space-x-3">
              <!-- TODO: harmonise input - placeholder? -->
              <input v-model="checked_agbs" id="agbs_check" name="agbs_check" type="checkbox" required
                class="w-5 h-5 rounded-full appearance-none ring-mossgray focus:ring-mossgray active:bg-mossgray checked:bg-mossgray"
                placeholder="E-Mail Adresse bestätigen" />
              <label for="agbs_check" class="text-sm font-semibold">
                <!-- TODO: Update text -->
                <a href="https://timelink.io/agb" target="_blank" class="underline">Allgemeinegeschäftsbedingungen</a>
                und <a href="https://timelink.io/datenschutz" target="_blank" class="underline">Datenschutz</a>
                akzeptiert!</label>
            </div>
            <div class="text-sm text-red-500" v-text="message"></div>

            <div>
              <!-- TODO: Harmonise button -->
              <button type="submit"
                class="relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white rounded-md group bg-mossgray hover:bg-white hover:text-mossgray ring-2 ring-mossgray focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-mossgray">
                <span class="absolute inset-y-0 left-0 flex items-center pl-3">
                  <!-- TODO: FontAwesome Icon -->
                  <!-- Heroicon name: solid/lock-closed -->
                  <svg class="w-5 h-5 text-white group-hover:text-mossgray" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                      d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                      clip-rule="evenodd" />
                  </svg>
                </span>
                Jetzt registrieren
              </button>
            </div>
          </form>
          <p class="mt-4 text-base leading-4 text-center">
            <router-link to="/login" class="text-sm font-medium hover:underline">
              Du hast bereits einen Account?
            </router-link>
          </p>
        </div>
        <div v-else>
          <div class="flex items-center">
            <div class="p-4 basis-1/4">
              <font-awesome-icon :icon="['fa-kit', 'tl-envelope-check']" size="6x" class="w-64 -ml-6 " />
            </div>
            <div class="space-y-2 basis-3/4">
              <p>
                <span class="text-lg font-semibold">Deine Registrierung war erfolgreich.</span><br />
                Du erhälst eine E-Mail mit einem Link zur Bestätigung deine E-Mail-Adresse.
              </p>
              <p class="text-sm">
                Solltest Du innerhalb weniger Minuten keine E-Mail erhalten, so prüfe bitte deinen SPAM-Ordner.
              </p>
            </div>
          </div>
        </div>

        <!-- TOOD: can be removed? -->
        <div class=""></div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import axios from 'axios'
import img from '@/assets/timelink_primary_logo_5f_moosgrauRGB.svg'

export default {
  components: {
    LoadingSpinner
  },
  setup() {
    const trial_days = APP_TEST_DAYS
    return { img, trial_days }
  },
  data() {
    return {
      email: null,
      email_confirmation: null,
      checked_agbs: false,
      message: null,
      showSpinner: false,
      success: false
    }
  },
  computed: {},
  methods: {
    async sendRegistration() {
      this.showSpinner = true
      // if (this.email != this.email_confirmation) {
      //   this.message = 'Die E-Mail-Adressen stimmen nicht überein.'
      //   this.showSpinner = false
      //   return
      // }
      try {
        let response = await axios.post(import.meta.env.VITE_API_URL + '/api/v1/registration', {
          email: this.email
        })
        if (response.data.success) {
          this.success = true
        }
      } catch (error) {
        console.log(error)
        if (error?.response) {
          if (error.response.status == 422) {
            if (error.response.data.errors.email.includes('The email has already been taken.')) {
              this.message = this.$t('registration.errors.already_taken')
            } else {
              this.message = this.$t('registration.errors.validation')
            }
          } else if (error.response.status != 500) {
            if (error.response.data.error_code == 10) {
              this.message = this.$t('registration.errors.company_is_registered')
            }
          } else {
            this.message = this.$t('error_message_try_later')
          }
        } else {
          this.message = this.$t('error_message_try_later')
        }
      }
      this.showSpinner = false
    }
  }
}
</script>
