<template>
  <TemplateComponent>
    <loading-spinner v-model="isLoading" :fullPage="true"></loading-spinner>

    <div class="flex items-center w-full py-3 mb-3">
      <h1 class="mr-auto text-2xl font-bold">
        {{ $t('subscription') }}
      </h1>
    </div>

    <!-- TRIAL ACTIVE ALERT -->
    <div class="mb-4">
      <AlertComponent v-if="company?.subscription?.trial_ends_at &&
        Date.parse(company?.subscription?.trial_ends_at) > Date.now()">
        <template #title>{{ $t('settings.subscriptions.test_phase_in_progress') }}</template>

        {{ $t('settings.subscriptions.test_phase_info_part_1') }}
        <b>{{ new Date(Date.parse(company?.subscription?.trial_ends_at)).toLocaleDateString() }}</b>
        {{ $t('settings.subscriptions.test_phase_info_part_2') }}
      </AlertComponent>

      <!-- TRIAL ENDED ALERT -->
      <AlertComponent v-else-if="company?.subscription?.product == 'trial' && !company?.subscription?.trial">
        <template #title>{{ $t('settings.subscription.test_phase_expired') }}</template>
        {{ $t('settings.subscription.subscribe_now') }}
      </AlertComponent>
    </div>



    <div class="pb-16 space-y-8 divide-y divide-mossgray-200">
      <div class="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-6">
        <!-- BILLING ADDRESS START -->
        <form class="bg-white shadow-sm ring-1 ring-mossgray-200 sm:rounded-xl md:col-span-4" ref="form"
          @submit.prevent="updateCompany">
          <div class="px-4 py-6 sm:p-8 sm:pt-6">
            <h2 class="mb-4 text-lg font-semibold leading-7">
              {{ $t('invoice_address') }}
            </h2>
            <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div class="sm:col-span-3">
                <label for="company_name" class="block text-sm font-medium leading-6 text-gray-900 label-required">{{
                  $t('company') }}</label>
                <div class="mt-2">
                  <input type="text" name="company_name" id="company_name" autocomplete="company" v-model="company.name"
                    class="w-full input" required />
                </div>
              </div>
              <div class="sm:col-span-3">
                <label for="company_email" class="block text-sm font-medium leading-6 text-gray-900 label-required">{{
                  $t('administrative_email') }}</label>
                <div class="mt-2">
                  <input type="email" name="company_email" id="company_email" autocomplete="company_email"
                    v-model="company.email" required class="w-full input" />
                </div>
              </div>
              <div class="sm:col-span-3">
                <label for="company_invoice_email"
                  class="block text-sm font-medium leading-6 text-gray-900 label-required">{{ $t('invoice_email')
                  }}</label>
                <div class="mt-2">
                  <input type="email" name="company_invoice_email" id="company_invoice_email"
                    autocomplete="company_invoice_email" v-model="company.invoice_email" required
                    class="w-full input" />
                </div>
              </div>

              <div class="sm:col-span-3">
                <label for="company_address" class="block text-sm font-medium leading-6 text-gray-900 label-required">{{
                  $t('address') }}</label>
                <div class="mt-2">
                  <input type="text" name="company_address" id="company_address" autocomplete="company_address"
                    v-model="company.address" required class="w-full input" />
                </div>
              </div>

              <div class="sm:col-span-3">
                <label for="company_zip" class="block text-sm font-medium leading-6 text-gray-900 label-required">{{
                  $t('zip') }}</label>
                <div class="mt-2">
                  <input type="text" name="company_zip" id="company_zip" autocomplete="company_zip"
                    v-model="company.zip" required class="w-full input" />
                </div>
              </div>
              <div class="sm:col-span-3">
                <label for="company_city" class="block text-sm font-medium leading-6 text-gray-900 label-required">{{
                  $t('city') }}</label>
                <div class="mt-2">
                  <input type="text" name="company_city" id="company_city" autocomplete="company_city"
                    v-model="company.city" required class="w-full input" />
                </div>
              </div>

              <div class="sm:col-span-3">
                <label for="company_phone" class="block text-sm font-medium leading-6 text-gray-900">{{ $t('phone')
                  }}</label>
                <div class="mt-2">
                  <input type="text" name="company_phone" id="company_phone" autocomplete="company_phone"
                    v-model="company.phone" class="w-full input" />
                </div>
              </div>
            </div>
          </div>

          <div class="flex items-center justify-end px-4 py-4 border-t gap-x-6 border-gray-900/10 sm:px-8">
            <!-- <BaseButton @click="openPortal" class="button-mossgray">
              Zahlungs-/Rechnungsportal öffnen
            </BaseButton> -->
            <!-- <button type="button" class="button-gray">Zurücksetzen</button> -->
            <button type="button" @click="updateCompany" class="button-mossgray">
              {{ $t('save') }}
            </button>
          </div>
        </form>
        <!-- BILLING ADDRESS END -->

        <!-- SUBSCRIPTION START -->
        <div class="flex items-center justify-center md:col-span-2">
          <div
            class="flex flex-col w-full h-full bg-white shadow-sm ring-1 ring-mossgray-200 sm:rounded-xl place-content-between">
            <div class="p-4 text-4xl font-bold text-center">
              <span> Basic Abo</span>
              <div v-if="company?.subscription?.ends_at" class="p-4 text-sm text-center text-gray-900">
                <span class="font-normal"> {{ $t('settings.subscription.ends_at') }} </span>
                <span class="ml-1 font-bold" v-text="new Date(Date.parse(company?.subscription?.ends_at)).toLocaleDateString(authUserStore.user.language ?? 'de', {
                  dateStyle: 'short'
                })"></span>
              </div>
            </div>
            <div class="flex flex-col">
              <div class="flex justify-center p-1 space-x-4 text-sm text-gray-600">
                <div class="text-4xl">7,90€</div>
                <div>
                  <div>{{ $t('settings.subscription.per') }}</div>
                  <div>{{ $t('settings.subscription.user') }}</div>
                </div>
              </div>
              <div class="text-xs font-semibold text-center text-gray-500">
                <!-- {{ $t('settings.subscription.presentation_without_discount') }} -->
                {{ $t('settings.subscription.monthly') }}

              </div>
            </div>
            <div class="flex flex-col p-4 space-y-2">
              <div class="flex items-baseline justify-center space-x-2">
                <input type="number" v-model="planed_quantity" class="w-24 input" id="quantity"
                  :disabled="this.company.autoupdate_quantity" /><span class="text-sm font-semibold text-gray-600">{{
                    $t('settings.subscription.user')
                  }}</span>
              </div>
              <div class="text-center break-words"
                v-if="planed_quantity != quantity && !this.company?.subscription?.trial_ends_at">
                <span v-text="quantity" class="pr-2 text-sm font-bold text-gray-600"></span><span
                  class="text-sm font-semibold text-gray-600">{{
                    $t('settings.subscription.user_until_end_of_month')
                  }}</span>
              </div>

              <div class="text-xs font-bold text-center text-apricot-600" v-if="this.company.autoupdate_quantity">
                {{ $t('settings.subscription.auto_update_licenses') }}
              </div>


            </div>
            <!-- <div class="flex justify-center" v-if="this.company.stripe_exists">
              <BaseButton @click="openPortal" class="button-lime">Zum Stripe-Kundenportal</BaseButton>
            </div> -->
            <div v-if="
              (company?.subscription?.trial && company?.subscription?.product == 'trial') ||
              company?.subscription?.status == 'canceled'
            " class="flex justify-end p-4 border-t border-gray-200">
              <BaseButton class="justify-center button-mossgray" @click="openSubscribeConfirmModal">{{
                $t('settings.subscription.subscribe')
              }}</BaseButton>
            </div>
            <div v-if="company?.subscription?.product != 'trial'"
              class="flex flex-row justify-around gap-4 p-4 border-t border-gray-200">
              <BaseButton class="justify-center button-mossgray" :disabled="disabledChangeButton"
                @click="showUpdateConfirmationModal = true"
                v-if="!company?.subscription?.ends_at && !company?.autoupdate_quantity"><span>{{ $t('change') }}</span>
              </BaseButton>
              <BaseButton v-else-if="company?.subscription?.ends_at" class="text-center button-mossgray"
                @click="showResumeSubscriptionModal = true">
                <span v-if="disabledChangeButton"> {{ $t('settings.subscription.resume') }}</span>
                <span v-else>{{ $t('settings.subscription.resume') }} + {{ $t('change') }}</span>
              </BaseButton>

              <BaseButton class="justify-center button-raspberry" @click="showCancelConfirmationModal = true"
                v-if="!this.company?.subscription?.ends_at">{{ $t('settings.subscription.cancel') }}</BaseButton>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- SUBSCRIPTION END -->

    <BaseModal v-model="showCheckoutConfirmationModal" @close-modal="showCheckoutConfirmationModal = false">
      <template #header> {{ $t('confirmations.subscription.subscribe') }} </template>

      <div class="p-6">
        <span>
          {{ $t('confirmations.subscription.subscribe_description') }}
        </span>
      </div>

      <template #footer>
        <div class="flex justify-end w-full gap-4">
          <BaseButton class="button-gray" type="button" @click="showCheckoutConfirmationModal = false"
            ref="cancelCheckoutConfirmationModalButton">
            {{ $t('cancel') }}</BaseButton>
          <BaseButton class="button-mossgray" @click="subscribe">{{ $t('confirm') }}</BaseButton>
        </div>
      </template>
    </BaseModal>
    <BaseModal v-model="showUpdateConfirmationModal" @close-modal="showUpdateConfirmationModal = false">
      <template #header> {{ $t('confirmations.subscription.change') }} </template>

      <div class="p-6">
        <span>
          {{ $t('confirmations.subscription.change_description') }}
        </span>
      </div>

      <template #footer>
        <div class="flex justify-end w-full gap-4">
          <BaseButton class="button-gray" type="button" ref="cancelUpdateConfirmationModalButton"
            @click="showUpdateConfirmationModal = false">
            {{ $t('cancel') }}
          </BaseButton>

          <BaseButton class="button-mossgray" @click="this.changeQuantity">
            {{ $t('confirm') }}
          </BaseButton>
        </div>
      </template>
    </BaseModal>
    <BaseModal v-model="showCancelConfirmationModal" @close-modal="showCancelConfirmationModal = false">
      <template #header> {{ $t('confirmations.subscription.cancel') }} </template>

      <div class="p-6">
        <span>
          {{ $t('confirmations.subscription.cancel_description') }}
        </span>
      </div>

      <template #footer>
        <div class="flex justify-end w-full gap-4">
          <BaseButton class="button-gray" type="button" @click="showCancelConfirmationModal = false"
            ref="cancelCancelConfirmationModalButton">
            {{ $t('cancel') }}</BaseButton>
          <BaseButton class="button-mossgray" @click="this.cancelSubscription">
            {{ $t('confirm') }}
          </BaseButton>
        </div>
      </template>
    </BaseModal>
    <BaseModal v-model="showCancelSuccessModal" @close-modal="showCancelSuccessModal = false">
      <template #header> {{ $t('confirmations.subscription.cancel_success') }} </template>

      <div class="p-6">
        <span>
          {{ $t('confirmations.subscription.cancel_success_description') }}
        </span>
      </div>

      <template #footer>
        <div class="flex justify-end w-full gap-4">
          <BaseButton class="button-mossgray" @click="showCancelSuccessModal = false"
            ref="nextCancelSuccessModalButton">
            {{ $t('next') }}
          </BaseButton>
        </div>
      </template>
    </BaseModal>
    <BaseModal v-model="showResumeSubscriptionModal" @close-modal="showResumeSubscriptionModal = false">
      <template #header> {{ $t('confirmations.subscription.renew') }} </template>

      <div class="p-6">
        <span>
          {{ $t('confirmations.subscription.renew_description') }}
        </span>
      </div>

      <template #footer>
        <div class="flex justify-end w-full gap-4">
          <BaseButton class="button-gray" type="button" @click="showResumeSubscriptionModal = false"
            ref="cancelResumeSubscriptionModalButton">
            {{ $t('cancel') }}</BaseButton>
          <BaseButton class="button-mossgray" @click="resumeSubscription">
            {{ $t('confirm') }}
          </BaseButton>
        </div>
      </template>
    </BaseModal>
    <BaseModal v-model="showSubscriptionSuccessModal" @close-modal="showSubscriptionSuccessModal = false">
      <template #header> {{ $t('confirmations.subscription.success') }} </template>

      <div class="p-6">
        <span>
          {{ $t('confirmations.subscription.success_description') }}
        </span>
      </div>

      <template #footer>
        <div class="flex justify-end w-full gap-4">
          <BaseButton class="button-mossgray" @click="showSubscriptionSuccessModal = false"
            ref="nextSubscriptionSuccessModalButton">
            {{ $t('next') }}
          </BaseButton>
        </div>
      </template>
    </BaseModal>
    <LoadingSpinner v-model="isLoading" :fullpage="true" :overAll="true" class="fixed top-0 left-0" />
  </TemplateComponent>
</template>

<script>
import { useCompanyStore } from '@/stores/company'
import BaseButton from '@/components/general/BaseButton.vue'
import BaseModal from '@/components/modals/BaseModal.vue'
import axios from 'axios'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import timelinkStoresService from '@/services/timelink-stores.service'
import { useAlertsStore } from '@/stores/alerts'
import TemplateComponent from '@/components/settings/TemplateComponent.vue'
import AlertComponent from '@/components/general/AlertComponent.vue'
import apiService from '@/services/api.service'
import { captureException } from '@sentry/vue'
import { useAuthUserStore } from '@/stores/auth-user'

export default {
  components: {
    BaseButton,
    BaseModal,
    LoadingSpinner,
    AlertComponent,
    TemplateComponent
  },
  setup() {
    const companyStore = useCompanyStore()
    const authUserStore = useAuthUserStore()
    return {
      companyStore,
      authUserStore
    }
  },
  data() {
    return {
      company: {},
      quantity: 1,
      planed_quantity: 1,
      showUpdateConfirmationModal: false,
      showCancelConfirmationModal: false,
      showCancelSuccessModal: false,
      showCheckoutConfirmationModal: false,
      showResumeSubscriptionModal: false,
      showSubscriptionSuccessModal: false,
      isLoading: false
      //   subscriptionData: {}
    }
  },
  mounted() {
    useCompanyStore().fetch(() => {
      this.company = { ...useCompanyStore().company }
      this.quantity = this.company?.subscription?.quantity ?? this.company?.active_users_count ?? 1
      this.planed_quantity = this.company?.licenses
    })
    this.company = { ...useCompanyStore().company }
    this.$echo
      .private('company.' + this.company.id)
      .listen('CompanyUpdated', (payload) => {
        let updateQuantity = false
        if (this.quantity == this.company?.subscription?.quantity) {
          this.quantity = payload.company?.subscription?.quantity
          this.planed_quantity = payload.company?.licenses

          updateQuantity = true
        }
        this.company = { ...payload.company }
        if (updateQuantity) {
          this.quantity =
            this.company?.subscription?.quantity ?? this.company?.active_users_count ?? 1
          this.planed_quantity = this.company?.licenses ?? 1
        }
      })
      .listen('.company.updated', (payload) => {
        let updateQuantity = false
        if (this.quantity == this.company?.subscription?.quantity) {
          this.quantity = payload.company?.subscription?.quantity ?? payload.company?.active_users_count ?? 1
          this.planed_quantity = payload.company?.licenses

          updateQuantity = true
        }
        this.company = { ...payload.company }
        if (updateQuantity) {
          this.quantity =
            this.company?.subscription?.quantity ?? this.company?.active_users_count ?? 1
          this.planed_quantity = this.company?.licenses ?? 1
        }
      })
    this.quantity = this.company?.subscription?.quantity ?? this.company?.active_users_count ?? 1
    this.planed_quantity = this.company?.licenses ?? 1

    // apiService.fetch(import.meta.env.VITE_API_URL + '/api/v1/')
  },
  computed: {
    disabledChangeButton: {
      get() {
        return this.planed_quantity == this.company?.licenses
      }
    }
  },
  watch: {
    showCheckoutConfirmationModalButton(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.$refs.cancelCheckoutConfirmationModalButton?.focus()
        })
      }
    },
    showCheckoutConfirmationModalButton(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.$refs.cancelUpdateConfirmationModalButton?.focus()
        })
      }
    },
    showCancelConfirmationModal(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.$refs.cancelCancelConfirmationModalButton?.focus()
        })
      }
    },
    showCancelSuccessModal(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.$refs.nextCancelSuccessModalButton?.focus()
        })
      }
    },
    showResumeSubscriptionModal(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.$refs.cancelResumeSubscriptionModalButton?.focus()
        })
      }
    },
    showSubscriptionSuccessModal(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.$refs.nextSubscriptionSuccessModalButton?.focus()
        })
      }
    }
  },
  methods: {
    async updateCompany() {
      if (!this.$refs.form.reportValidity()) {
        return
      }
      this.isLoading = true
      await this.companyStore.updateData({
        name: this.company.name,
        address: this.company.address,
        email: this.company.email,
        invoice_email: this.company.invoice_email,
        city: this.company.city,
        zip: this.company.zip,
        phone: this.company.phone
      })
      useAlertsStore().successfullySaved()
      this.isLoading = false
    },
    async openSubscribeConfirmModal() {
      if (!this.$refs.form.reportValidity()) {
        return
      }
      if (Date.parse(this.companyStore.company.subscription.trial_ends_at) < Date.now()) {
        this.subscribe()
        return
      }
      this.showCheckoutConfirmationModal = true
    },
    async subscribe() {
      this.showCheckoutConfirmationModal = false
      if (!this.$refs.form.reportValidity()) {
        return
      }
      this.isLoading = true
      if (
        this.companyStore.company.tl.origin.address == null ||
        this.companyStore.company.tl.origin.city == null ||
        this.companyStore.company.tl.origin.name == null ||
        this.companyStore.company.tl.origin.zip == null
      ) {
        await this.updateCompany()
        this.isLoading = true
      }
      let redirect_url = null
      redirect_url = window.location.toString()
      redirect_url = redirect_url.substring(
        0,
        redirect_url.indexOf(this.$route.path) + this.$route.path.length
      )
      // return
      axios
        .post(import.meta.env.VITE_API_URL + '/api/v1/subscription/subscribe', {
          quantity: this.planed_quantity,
          success: redirect_url + '?success',
          canceled: redirect_url + '?canceled'
        })
        .then((resp) => {
          let url = resp.data.url
          this.isLoading = false
          window.location.assign(url)
        })
        .catch(() => {
          useAlertsStore().error(this.$t('errors.ups'), 60)
          this.isLoading = false
        })
    },
    changeQuantity() {
      //TODO: Add modal with ask for submit

      this.isLoading = true
      this.showUpdateConfirmationModal = false
      axios
        .patch(import.meta.env.VITE_API_URL + '/api/v1/subscription', {
          quantity: this.planed_quantity
        })
        .then((response) => {
          // console.log(response)
          this.showUpdateConfirmationModal = false
          this.isLoading = false
          if (response?.data?.success) {
            useAlertsStore().successfullySaved()
          } else {
            if (response?.data?.errorCode) {
              useAlertsStore().error(
                this.$t(
                  'errors.subscription.changeQuantity.' + response.data.errorCode,
                  (response.data.errorCode == 'tooManyActiveUsers') ? { count: response?.data?.count } : null)
                , 30)
            }
            else {
              useAlertsStore().error(this.$t('errors.ups'))
              useAlertsStore().error(response?.data?.message, 300)
            }
          }
        })
        .catch((error) => {
          console.log(error)
          useAlertsStore().error(this.$t('errors.ups'))
          this.isLoading = false
        })
    },
    cancelSubscription() {
      this.isLoading = true
      this.showCancelConfirmationModal = false
      axios
        .post(import.meta.env.VITE_API_URL + '/api/v1/subscription/cancel', {})
        .then(() => {
          this.showCancelConfirmationModal = false
          this.showCancelSuccessModal = true
          this.isLoading = false
        })
        .catch(() => {
          useAlertsStore().error(this.$t('errors.ups'))
          this.isLoading = false
        })
    },
    resumeSubscription() {
      this.isLoading = true
      this.showResumeSubscriptionModal = false
      axios
        .post(import.meta.env.VITE_API_URL + '/api/v1/subscription/resume', {})
        .then(() => {
          this.showResumeSubscriptionModal = false
          this.showSubscriptionSuccessModal = true
          this.isLoading = false
        })
        .catch(() => {
          useAlertsStore().error(this.$t('errors.ups'))
          this.isLoading = false
        })
    },
    async openPortal() {
      this.isLoading = true

      try {
        let redirect_url = null
        redirect_url = window.location.toString()
        redirect_url = redirect_url.substring(
          0,
          redirect_url.indexOf(this.$route.path) + this.$route.path.length
        )
        let response = await axios.post(import.meta.env.VITE_API_URL + '/api/v1/subscription/portal', { return_url: redirect_url })
        window.location.assign(response.data.url);
      }
      catch (error) {
        if (apiService.checkErrorAndNotify(error)) {
          //
        }
        else {
          captureException(error)
          console.log(error)
        }
      }
    }
  }
}
</script>
