<template>
  <div x-show="" class="fixed z-50 flex justify-center max-w-xl mx-auto -mt-2 right-5 top-20 min-w-96">
    <div class="flex flex-col w-full max-w-lg space-y-2 max-h-96 overscroll-y-auto">
      <TransitionGroup>
        <template v-for="notification in popUpNotifications" :key="notification.id">
          <div class="w-full max-w-96">
            <div class="flex justify-between w-full max-w-lg space-x-4 bg-white drop-shadow rounded-2xl">
              <div class="flex flex-col px-4 py-2">
                <!-- TODO: pass amount only for specific types -->
                <span class="font-bold">{{ $t('notifications.type.' + notification.type) }}</span>
                <span>{{ $t('notifications.text.' + notification.type, notification.data)
                  }}</span>
              </div>
              <div class="flex items-start p-3 text-lg leading-3">
                <button type="button" @click="notificationsStore.hidePopup(notification.id)">
                  <font-awesome-icon :icon="['fa-kit', 'tl-cross']" />
                </button>
              </div>
            </div>
          </div>
        </template>
      </TransitionGroup>
    </div>
  </div>
</template>

<script setup>
import timelinkStoresService from '@/services/timelink-stores.service'
import { useNotificationsStore } from '@/stores/notifications'
import { computed, ref } from 'vue'

const notificationsStore = useNotificationsStore()
const notifications = ref([])
const time = ref(Date.now())

// TODO: move display time to a settings file
const popUpNotifications = computed(() => {
  return notificationsStore.notifications.filter(
    (item) => Date.parse(item.created_at) > time.value - 10 * 1000 && item.popupHidden == false
  )
})

notificationsStore.$subscribe((mutation, payload) => { })

timelinkStoresService.setOrRenewInterval(
  'notificationPopup',
  'notificationPopup',
  () => {
    time.value = Date.now()
  },
  2_000
)
</script>
