<template>
  <div class="">
    <Popover v-slot="{ open }">
      <PopoverButton class="button-white !px-2 aspect-square" @click="toggleState(open)">
        <!-- TODO: Filter unread notifications only -->
        <font-awesome-icon :icon="['fa-kit', 'tl-bell']" size="lg"
          :class="{ 'fa-shake text-red-600': notificationsStore.getUnreadCount > 0 }"
          style="--fa-animation-duration: 1.5s;" />
      </PopoverButton>

      <PopoverPanel class="absolute z-10 -mt-2 w-96 right-5 top-20 bottom-20 drop-shadow">
        <div
          class="grid items-center max-h-full grid-cols-5 gap-0 overflow-y-auto bg-white border divide-y rounded-xl border-mossgray-200 divide-mossgray-200">
          <div class="col-span-3 px-4 py-2 font-bold">
            Benachrichtigungen ({{ notificationsStore.notifications.length }})
          </div>
          <div v-if="notificationsStore.getUnreadCount > 1" class="col-span-1 col-end-6 px-4 py-3 text-end">
            <button class="button button-mossgray" @click="notificationsStore.markAllAsRead"
              v-tippy="$t('notifications.markAllAsRead')">
              <font-awesome-icon :icon="['far', 'check-double']" size="sm" />
            </button>
          </div>
          <template v-if="notificationsStore.notifications.length == 0">
            <div class="w-full col-span-5 p-2 py-10 text-center">
              Keine Benachrichtigungen
            </div>
          </template>
          <!-- TODO: Notification counter overview X info, X error -->

          <template v-for="notification in notificationsStore.notifications" :key="notification.id">
            <div class="col-span-5">
              <div class="w-full px-4 py-3" :class="{
                'bg-mossgray-50': notification.read_at,
                'bg-white': !notification.read_at
              }">
                <div class="flex flex-col">
                  <div class="font-bold">
                    {{ $t('notifications.type.' + notification.type) }}
                    <div class="float-right text-xs font-normal text-right">
                      {{ new Date(Date.parse(notification.created_at)).toLocaleString("de-DE", {
                        hour: "numeric",
                        minute: "numeric",
                        year: "2-digit",
                        month: "numeric",
                        day: "numeric",
                      }) }}
                      <span v-if="false">
                        {{ notification.read_at ? 'und gelesen um' : '' }}
                        {{
                          notification.read_at
                            ? new Date(Date.parse(notification.read_at)).toLocaleString()
                            : ''
                        }}</span>
                    </div>
                  </div>
                  <span>{{ $t('notifications.text.' + notification.type, notification.data) }}</span>
                  <div class="mt-1 place-self-end">
                    <button
                      @click="notification.severity.type == 'error' ? notificationsStore.markAsRead(notification.id) : notificationsStore.delete(notification.id)"
                      v-show="!notification.read_at" class="button-mossgray !px-2.5 !py-1.5"
                      v-tippy="$t('notifications.markAsRead')">
                      <font-awesome-icon :icon="['fa-kit', 'tl-check']" class="text-lg" />
                    </button>
                    <button @click="notificationsStore.delete(notification.id)" v-show="notification.read_at"
                      class="button button-red !px-2.5 !py-1.5" v-tippy="$t('delete')">
                      <font-awesome-icon :icon="['fa-kit', 'tl-trash']" class="text-lg" />
                    </button>
                    <!-- <button
                    @click="notificationsStore.delete(notification.id)"
                    :class="{ 'row-span-2': notification.read_at }"
                  >
                    Delete
                  </button> -->
                  </div>
                </div>

                <div v-if="false">
                  {{ notification }}
                  <router-link v-if="notification.action?.route" :to="notification.action?.route">{{
                    notification?.action?.i18n
                    }}</router-link>
                </div>
              </div>
            </div>
          </template>
        </div>
      </PopoverPanel>
    </Popover>
  </div>
</template>

<script setup>
import { $t } from '@/config/i18n'
import { useNotificationsStore } from '@/stores/notifications'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { ref } from 'vue'

const notificationCenter = ref(null)
const notificationsStore = useNotificationsStore()

function toggleState(open) {
  if (!open) {
    notificationsStore.notifications.forEach(item => {
      item.popupHidden = true
    })
  }
}

</script>
