<template>
  <div class="p-4 rounded-md" :class="{
    'bg-mossgray-100': props.type == 'info',
    'bg-apricot-100 text-apricot-800': props.type == 'warning',
    'bg-red-100 text-red-800': props.type == 'error'
  }">
    <div class="flex items-center">
      <div class="flex-shrink-0">
        <font-awesome-icon :icon="['fa-kit', 'tl-info']" size="lg" />
      </div>
      <div class="ml-3">
        <template v-if="$slots.title">
          <h3 class="text-base font-bold">
            <slot name="title"></slot>
          </h3>
        </template>

        <p class="text-sm font-medium">
          <slot></slot>
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  title: {
    type: String,
    default: null
  },
  type: {
    type: String,
    default: 'info'
  }
})
</script>
