<template>
  <BaseModal v-model="showModal" @close-modal="closeModal">
    <template v-slot:header> {{ $t('create_user') }} </template>
    <form @submit.prevent="save" ref="form">
      <div class="px-4 py-6 sm:p-8">
        <div class="grid max-w-2xl grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
          <div class="sm:col-span-3">
            <BaseInput :validation-errors="validationErrors.first_name" :disabled="!canEdit" type="text"
              name="first_name" id="first_name" autocomplete="first_name" v-model="user.first_name"
              ref="first_name_input" :required="true">
              {{ $t('first_name') }}
            </BaseInput>
            <!-- <label for="first_name" class="block text-sm font-medium leading-6 text-gray-900 label-required">{{
              $t('first_name') }}</label>
            <div class="mt-2">
              <input :disabled="!canEdit" type="text" name="first_name" id="first_name" autocomplete="first_name"
                v-model="user.first_name" class="w-full input" ref="first_name_input" required />
            </div> -->
          </div>
          <div class="sm:col-span-3">
            <BaseInput :validation-errors="validationErrors.last_name" :disabled="!canEdit" type="text" name="last_name"
              id="last_name" autocomplete="last_name" v-model="user.last_name" :required="true">
              {{ $t('last_name') }}
            </BaseInput>
            <!-- <label for="last_name" class="block text-sm font-medium leading-6 text-gray-900 label-required">{{
              $t('last_name') }}</label>
            <div class="mt-2">
              <input :disabled="!canEdit" type="text" name="last_name" id="last_name" autocomplete="last_name"
                v-model="user.last_name" required class="w-full input" />
            </div> -->
          </div>
          <div class="sm:col-span-3">
            <BaseInput :validation-errors="validationErrors.email" :disabled="!canEdit" type="email" name="email"
              id="email" autocomplete="email" v-model="user.email" :required="true">
              {{ $t('email') }}
            </BaseInput>

            <!-- <label for="email" class="block text-sm font-medium leading-6 text-gray-900 label-required">{{ $t('email')
              }}</label>
            <div class="mt-2">
              <input :disabled="!canEdit" type="email" name="email" id="email" autocomplete="email" v-model="user.email"
                required class="w-full input" /> 
          </div> -->
          </div>

          <div class="sm:col-span-3 sm:col-end-8">
            <BaseInput :validation-errors="validationErrors.active" :disabled="!canEdit" type="checkbox" name="active"
              id="active" autocomplete="active" v-model="user.active">
              {{ $t('active') }}
            </BaseInput>
            <!-- <label for="active" class="block text-sm font-medium leading-6 text-gray-900">{{
              $t('active')
              }}</label>
            <div class="mt-2">
              <input :disabled="!canEdit" type="checkbox" name="active" id="active" autocomplete="Off"
                v-model="user.active" class="checkbox" />
            </div> -->
          </div>
        </div>
      </div>
    </form>
    <template v-slot:footer>
      <div class="flex items-center justify-end w-full space-x-4">
        <BaseButton class="button-gray" @click="closeModal" type="button">{{
          $t('cancel')
        }}</BaseButton>

        <BaseButton class="button-mossgray" type="submit" @click="save" :disabled="isLoading">
          {{ $t('create') }}
        </BaseButton>
      </div>
    </template>
  </BaseModal>
</template>

<script setup>
import BaseButton from '@/components/general/BaseButton.vue'
import BaseModal from '@/components/modals/BaseModal.vue'
import apiService from '@/services/api.service'
import { useCompanyStore } from '@/stores/company'
import { useAuthUserStore } from '@/stores/auth-user'
import { useAlertsStore } from '@/stores/alerts'
import { captureException, captureMessage } from '@sentry/vue'
import { computed, nextTick, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { $t } from '@/config/i18n'
import BaseInput from '../general/BaseInput.vue'
import { useKeySave } from '@/composables/keySave'

const companyStore = useCompanyStore()
const authUserStore = useAuthUserStore()
const alertsStore = useAlertsStore()
const route = useRoute()
const isLoading = ref(false)

const props = defineProps(['modelValue'])
const emit = defineEmits(['update:modelValue'])
const form = ref(null)

const showModal = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value)
})


function closeModal() {
  showModal.value = false
  user.value = {
    first_name: '',
    last_name: '',
    email: '',
    active: true,
  }
}


const user = ref({
  first_name: '',
  last_name: '',
  email: '',
  active: true,
})
const validationErrors = ref([])
const canEdit = computed(() => {
  return authUserStore.user.admin >= 9 && companyStore.subscriptionActive
})

//TODO: Update to work with edit + escape key
const saveOnKeyDown = useKeySave(() => {
  if (!showModal.value) {
    return
  }
  if (isLoading.value) {
    return
  }
  save()
})
async function save() {
  if (authUserStore.user.admin < 9) {
    //TODO: Add notification or message somewhere
    alertsStore.error($t('errors.no_permissions'))
    return
  }
  await nextTick()
  if (!form.value.reportValidity()) {
    return
  }
  isLoading.value = true
  try {
    let response = await apiService.create(
      import.meta.env.VITE_API_URL + '/api/v1/users',
      user.value
    )
    if (response.success) {
      showModal.value = false
      alertsStore.success(
        $t('successfully.created_user', {
          firstName: user.value.first_name,
          lastName: user.value.last_name
        })
      )
      if (user.value.active && user.value.active != response.data.active && authUserStore.user.admin >= 9) {
        alertsStore.warning($t('settings.user.could_not_be_activated'), 10)
      }
      user.value = {
        first_name: '',
        last_name: '',
        email: '',
        active: true
      }
    } else {
      alertsStore.error($t('errors.ups_create'))
      captureMessage('Could not create user!')
      // TODO: Add handling
    }
  } catch (error) {
    if (apiService.checkErrorAndNotify(error)) {
      //
    } else if ((validationErrors.value = apiService.checkForValidationErrorAndConvert(error))) {
      // 
    } else {
      alertsStore.error($t('errors.ups'))
      captureException(error)
    }
  }
  isLoading.value = false
}
</script>
