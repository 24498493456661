<template>
    <div class="sm:col-span-3">
        <BaseInput v-model="model.name" :disabled="!canEdit && !excludedFields.includes('name')" type="text" name="name"
            id="name" :validation-errors="validationErrors.name" autocomplete="name" :required="true">{{
                $t('name') }}
        </BaseInput>
    </div>

    <div class="sm:col-span-3" v-if="model.acronym !== undefined">
        <BaseInput v-model="model.acronym" :disabled="!canEdit && !excludedFields.includes('acronym')" type="text"
            name="acronym" id="acronym" autocomplete="acronym" :validation-errors="validationErrors.acronym">{{
                $t('acronym') }}</BaseInput>
    </div>

    <div class="sm:col-span-6">
        <BaseInput :disabled="!canEdit && !excludedFields.includes('info')" type="text" name="info" id="info"
            autocomplete="Off" v-model="model.info" :validation-errors="validationErrors.info" :text-area="true">{{
                $t('info')
            }}</BaseInput>
    </div>
    <div class="sm:col-span-2">
        <BaseInput :disabled="!canEdit && !excludedFields.includes('active')" type="checkbox" name="active" id="active"
            autocomplete="Off" v-model="model.active">{{ $t('active') }}</BaseInput>
    </div>
    <div class="sm:col-span-2" v-if="model.billable !== undefined">
        <BaseInput :disabled="!canEdit && !excludedFields.includes('billable')" type="checkbox" name="billable"
            id="billable" autocomplete="Off" v-model="model.billable">{{ $t('billable') }}</BaseInput>
    </div>
    <div class="sm:col-span-2">
        <BaseInput :disabled="!canEdit && !excludedFields.includes('color')" type="color" name="color" id="color"
            autocomplete="Off" v-model="model.color" :validation-errors="validationErrors.color">{{
                $t('color') }}</BaseInput>
    </div>
</template>

<script setup>
import BaseInput from '@/components/general/BaseInput.vue';
import timelinkStoresService from '@/services/timelink-stores.service';
import { ref } from 'vue';

const props = defineProps({
    validationErrors: {
        type: [Array, Object],
        default: []
    },
    canEdit: {
        type: Boolean,
        default: true
    },
    excludedFields: {
        type: Array,
        default: []
    }
})
// const randomColor = timelinkStoresService.getRandomColorFromPreset()
const model = defineModel({
    default: {
        name: null,
        acronym: null,
        info: null,
        active: true,
        billable: true,
        color: timelinkStoresService.getRandomColorFromPreset()
    }
})
</script>