<template>
    <div class="flex flex-row space-x-2 ">
        <div class="flex items-center">
            <HeadlessSwitch v-model="switchModel" :class="[
                switchModel ? 'bg-lime' : 'bg-gray-200',
                'relative inline-flex h-5 w-9 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-1 focus:ring-mossgray focus:ring-offset-2'
            ]">
                <span aria-hidden="true" :class="[
                    switchModel ? 'translate-x-4' : 'translate-x-0',
                    'pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                ]" />
            </HeadlessSwitch>
        </div>
        <label for="">
            <slot>Label for Switch</slot>
        </label>
    </div>
</template>

<script setup>
import { Switch as HeadlessSwitch } from '@headlessui/vue';
const switchModel = defineModel({
    default: true,
    type: Boolean
})

const props = defineProps({
    label: {
        default: null,
        type: String
    }
})
</script>