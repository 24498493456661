<template>
  <TemplateComponent>
    <loading-spinner v-model="isLoading" :fullPage="true"></loading-spinner>
    <div class="flex items-center justify-between w-full py-3 mb-3">
      <h1 class="text-2xl font-bold">
        {{ $t('edit_project') }}
      </h1>
      <span v-if="saveComplete"
        class="inline-block pb-1 text-base font-bold text-green-500 border-b-4 border-green-500 md:ml-4">{{
          $t('success_message') }}</span>

      <div class="flex flex-row py-3 pl-3 space-x-4">
        <BaseButton class="button-mossgray" @click="showExportModal = true">
          <font-awesome-icon :icon="['fa-kit', 'tl-file-export']" class="mr-2 text-lg" />
          {{ $t('settings.projects.export') }}
        </BaseButton>
      </div>
    </div>
    <!-- TODO: Rework -->
    <div class="pb-16 space-y-8 divide-y divide-mossgray-200">
      <div class="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
        <div class="px-4 sm:px-0">
          <h2 class="text-base font-semibold leading-7">
            {{ $t('project_edit_header') }}
          </h2>

          <div class="w-full h-full col-span-1">
            <div class="flex flex-col items-center justify-center w-full h-full gap-2">
              <div
                class="flex items-center justify-center w-32 h-32 text-sm leading-6 text-center text-gray-600 aspect-square squircle-clip"
                :style="{
                  color: !project.image_id
                    ? timelinkService.generateForegroundColor(
                      project.color ? project.color : timelinkService.generateBackgroundColor(project)
                    )
                    : null,
                  backgroundColor: !project.image_id
                    ? project.color
                      ? project.color
                      : timelinkService.generateBackgroundColor(project)
                    : null
                }">
                <!-- {{ $t('project_edit_description') }} -->
                <img v-if="project.image_id" :src="timelinkService.getImageFor(project)" class="object-contain" />
                <span class="text-4xl font-bold" v-else v-text="timelinkService.acronymOrShortName(project)"></span>
              </div>
              <div class="flex flex-col mt-3 space-y-2" v-if="companyStore.subscriptionActive">
                <!-- TODO: Translation -->
                <BaseButton class="justify-center button button-lime" @click="showImageUpload = true">{{
                  $t('upload_image') }}
                </BaseButton>
                <BaseButton class="justify-center button button-raspberry" v-if="project.image_id"
                  @click="showImageDeleteModal = true">{{ $t('delete') }}</BaseButton>
              </div>
            </div>
          </div>
        </div>

        <form class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2" @submit.prevent="save">
          <div class="px-4 py-6 sm:p-8">
            <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <CPInputs v-model="project" :validation-errors="validationErrors" :can-edit="canEdit"
                :excluded-fields="excludedFields"></CPInputs>
            </div>
          </div>

          <div class="flex items-center px-4 py-4 border-t gap-x-6 border-gray-900/10 sm:px-6" :class="{
            'justify-end': companyStore.company.pull_provider,
            'justify-between': !companyStore.company.pull_provider
          }">
            <BaseButton class="button-red" type="button" @click="showDeleteModal = true"
              v-if="!companyStore.company.pull_provider" :disabled="!canEdit">
              {{ $t('delete') }}
            </BaseButton>

            <div v-if="Boolean(companyStore.company?.pull_provider)">
              {{ $t('managed_through') }}
              <span class="font-medium">{{ companyStore.company.pull_provider }}</span>.
            </div>
            <button v-if="!companyStore.company.pull_provider || excludedFields.length != 0" type="submit"
              class="button-mossgray"
              :disabled="isLoading || authUserStore.user.admin >= 9 && excludedFields.length == 0">
              {{ $t('save') }}
            </button>
          </div>
        </form>
      </div>
    </div>
    <div>
      <div class="flex flex-col py-2 space-y-2">
        <div class="text-lg">
          {{ $t('page.projects.edit.total_time') }}:
          <span class="ml-2 font-mono">{{ datetime.calcAll(timeEntries) }}</span>
        </div>

        <TimeEntryList :timeEntries="timeEntries" :showBreaks="false" :showActionButtons="false">
        </TimeEntryList>
      </div>
    </div>

    <BaseModal v-model="showDeleteModal" @close-modal="showDeleteModal = false">
      <template #header> {{ $t('confirm_deletion') }} </template>

      <div class="p-6">
        <span>
          {{ $t('confirm_deletion_descriptions.project') }}
        </span>
      </div>

      <template #footer>
        <div class="flex justify-end w-full gap-4">
          <BaseButton class="button-gray" type="button" @click="showDeleteModal = false" ref="cancelDeleteModalButton">
            {{ $t('cancel') }}</BaseButton>
          <BaseButton class="button-red" :disabled="!timer || timer.seconds > 0" @click="deleteProject"><span
              class="w-6" v-if="timer && timer.seconds > 0"> ({{ timer.seconds }}) </span>{{ $t('delete') }}
          </BaseButton>
        </div>
      </template>
    </BaseModal>

    <ExportModal v-model="showExportModal" :project_id="route.params.project_id"></ExportModal>
    <ImageDeleteModal v-model="showImageDeleteModal" @confirmed="deleteImage"></ImageDeleteModal>

  </TemplateComponent>
  <template>
    <ImageUploadModal v-model="showImageUpload" :uploadUrl="uploadUrl" @upload-successfully="onUploadSuccessfully" />
  </template>
</template>

<script setup>
import apiService from '@/services/api.service'
import timelinkStoresService from '@/services/timelink-stores.service'
import featureFlagsService from '@/services/feature-flags.service'

import { useCompanyStore } from '@/stores/company'
import { useAuthUserStore } from '@/stores/auth-user'
import { useProjectsStore } from '@/stores/projects'
import { useClientsStore } from '@/stores/clients'
import { useServicesStore } from '@/stores/services'
import { useAlertsStore } from '@/stores/alerts'
import { useTimeEntryStore } from '@/stores/timeEntry'

import { useTimer } from 'vue-timer-hook'
import { AxiosError } from 'axios'
import datetime from '@/lib/datetime'
import { captureException, captureMessage } from '@sentry/vue'

import LoadingSpinner from '@/components/LoadingSpinner.vue'
import BaseButton from '@/components/general/BaseButton.vue'
import CPInputs from './component/CPInputs.vue'
import TimeEntryList from '@/components/general/TimeEntryList.vue'
import TemplateComponent from '@/components/settings/TemplateComponent.vue'
import BaseModal from '@/components/modals/BaseModal.vue'
import BaseInput from '@/components/general/BaseInput.vue'
import ExportModal from '@/components/modals/ExportModal.vue'
import ImageUploadModal from '@/components/modals/ImageUploadModal.vue'
import ImageDeleteModal from '@/components/modals/ImageDeleteModal.vue'

import { nextTick, onMounted, ref, toValue, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { $t } from '@/config/i18n'
import { errorMessages } from 'vue/compiler-sfc'

const timelinkService = timelinkStoresService
const authUserStore = useAuthUserStore()
const companyStore = useCompanyStore()
const timeEntryStore = useTimeEntryStore()
const clientsStore = useClientsStore()
const projectsStore = useProjectsStore()
const servicesStore = useServicesStore()
const alertsStore = useAlertsStore()
const feature = featureFlagsService
const dt = datetime
const route = useRoute()
const router = useRouter()


const canEdit = ref(false)
const project = ref({
  name: null,
  info: null,
  acronym: null
})
const excludedFields = ref(['acronym', 'color'])

const validationErrors = ref([])
const isLoading = ref(true)
const saveComplete = ref(null)
const showDeleteModal = ref(false)
const timer = ref(null)
const timeEntries = ref([])
const showExportModal = ref(false)
const showImageUpload = ref(false)
const uploadUrl = ref(import.meta.env.VITE_API_URL + '/api/v1/projects/' + route.params.project_id + '/image')

const cancelDeleteModalButton = ref(null)
const showImageDeleteModal = ref(false)
const cancelImageDeleteModalButton = ref(null)


watch(() => showDeleteModal.value, async (newVal) => {
  if (newVal) {
    timer.value = timer.value = useTimer(new Date(Date.now() + 2 * 1000), true)
    await nextTick()
    cancelDeleteModalButton.value?.focus()
  }
})

watch(
  () => showImageDeleteModal.value,
  async (newVal) => {
    if (newVal) {
      await nextTick()
      cancelImageDeleteModalButton.value?.focus()
    }
  }
)


onMounted(() => {
  canEdit.value = authUserStore.user.admin >= 9 && !companyStore.company.pull_provider && companyStore.subscriptionActive
  fetch()
  fetchTimeEntries()
})

async function fetch() {
  isLoading.value = true
  try {
    let data = await apiService.fetchId(
      import.meta.env.VITE_API_URL + '/api/v1/projects',
      route.params.project_id
    )
    project.value = data.data
    project.value.tl = { image: null }
    isLoading.value = false
  } catch (error) {
    isLoading.value = false

    if (error instanceof AxiosError) {
      if (error?.response?.status == 404 || error?.response?.status == 403) {
        router.push({
          name: 'ClientEdit',
          params: {
            client_id: route.params.client_id
          }
        })
        alertsStore.error($t('errors.project.not_found'))
      }
    }
  }

  isLoading.value = false
}
async function save() {
  if (companyStore.company.pull_provider && excludedFields.value.length == 0 || authUserStore.user.admin < 5) {
    alertsStore.error($t('errors.no_permissions'))
    return
  }
  isLoading.value = true

  try {
    let response = await apiService.update(
      import.meta.env.VITE_API_URL + '/api/v1/projects',
      route.params.project_id,
      {
        ...project.value
      }
    )
    if (response.success) {
      alertsStore.successfullySaved()
      timelinkStoresService.getImageFor(project.value)
      projectsStore.updateIfExists(response.data)
    } else {
      alertsStore.error($t('errors.ups_save'))
      captureMessage('Project save got success = false')
    }
  } catch (error) {
    if (
      apiService.checkErrorAndNotify(error)
    ) {
      //
    }
    else if ((validationErrors.value = apiService.checkForValidationErrorAndConvert(error))) {
      //
    }
    else {
      alertsStore.error($t('errors.ups'))
      captureException(error)
    }
  }
  isLoading.value = false
}

function deleteProject() {
  isLoading.value = true
  const name = toValue(project.value.name)
  apiService.delete(
    import.meta.env.VITE_API_URL + '/api/v1/projects',
    route.params.project_id,
    {},
    () => {
      alertsStore.success($t('successfully.deleted_project', { name: name }))
      router.push({
        name: 'ClientEdit',
        params: { client_id: route.params.client_id }
      })
    },
    (error) => {
      if (apiService.checkErrorAndNotify(error)) {
        //
      } else {
        alertsStore.error($t('errors.ups'))
        captureException(error)
      }
      isLoading.value = false
    }
  )
}

function fetchTimeEntries() {
  timeEntries.value = []
  //TODO: Add all parameter to get all entries if it is an project manager or admin for this project
  apiService.fetchAll(
    import.meta.env.VITE_API_URL + '/api/v1/timeEntries',
    {
      project_id: route.params.project_id,
      withRelations: true,
      limit: 10
    },
    (data) => {
      if (Array.isArray(data.data)) {
        data.data.forEach((item) => {
          if (item.client) {
            clientsStore.addOrUpdate(item.client)
          }
          if (item.service) {
            servicesStore.addOrUpdate(item.service)
          }
          timeEntries.value.push(item)
        })
      }
    },
    (error) => {
      captureException(error)
    }
  )
}

function onUploadSuccessfully(data) {
  project.value = data
  projectsStore.updateIfExists(data)
  project.value.tl = {
    image: null
  }
}

function calcDay(day) {
  return datetime.calcDay(day, timeEntries.value)
}

function calcWeek(day) {
  return datetime.calcWeek(day, timeEntries.value)
}

async function deleteImage() {
  isLoading.value = true
  showImageDeleteModal.value = false
  const image_id = project.value.image_id
  if (!image_id) {
    project.value.image_id = null
    if (project.value?.tl?.image) {
      project.value.tl.image = null
    } else {
      project.value.tl = {
        image: null
      }
    }
    return
  }
  try {
    let imageCacheId = null
    try {
      let respImage = await axios.get(
        import.meta.env.VITE_API_URL + '/api/v1/img/' + project.value.image_id,
        { validateStatus: false }
      )
      imageCacheId = respImage.id
    } catch (error) {
      //
    }
    let response = await apiService.delete(
      import.meta.env.VITE_API_URL + '/api/v1/img',
      project.value.image_id
    )
    if (response.success) {
      alertsStore.success($t('successfully.deleted_image'))
      project.value.image_id = null
      if (project.value?.tl?.image) {
        project.value.tl.image = null
      } else {
        project.value.tl = {
          image: null
        }
      }
      if (imageCacheId) {
        axios.storage.remove(imageCacheId)
      }
      projectsStore.addOrUpdate({
        id: project.value.id,
        image_id: project.value.image_id,
        updated_at: datetime.iso(Date.now())
      })
    } else {
      alertsStore.error($t('errors.ups_delete'))
    }
  } catch (error) {
    if (error?.response?.status == 404) {
      alertsStore.success($t('successfully.deleted_image'))
      project.value.image_id = null
      if (project.value?.tl?.image) {
        project.value.tl.image = null
      } else {
        project.value.tl = {
          image: null
        }
      }
      if (imageCacheId) {
        axios.storage.remove(imageCacheId)
      }
      projectsStore.addOrUpdate({
        id: project.value.id,
        image_id: project.value.image_id,
        updated_at: datetime.iso(Date.now())
      })
    } else if (apiService.checkErrorAndNotify(error)) {
      //
    } else {
      alertsStore.error($t('errors.ups'))
      console.log(error)
      captureException(error)
    }
  }
  isLoading.value = false
}
</script>
