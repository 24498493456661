<template>
  <label :for="name" class="block text-sm font-medium leading-6 text-gray-900">
    <slot>Label</slot>
  </label>
  <div class="mt-2">
    <template v-if="textArea">
      <textarea :disabled="props.disabled" :type="props.type" :name="props.name" :id="props.id"
        :autocomplete="props.autocomplete" v-model="model" class="w-full textarea"></textarea>
    </template>
    <template v-else>
      <input :disabled="props.disabled" :type="props.type" :name="props.name" :id="props.id"
        :autocomplete="props.autocomplete" v-model="model" :required="props.required"
        :class="{ 'w-full': props.type != 'checkbox' && props.type != 'color', 'input': props.type != 'checkbox', 'checkbox': props.type == 'checkbox' }" />
    </template>
  </div>
  <div class="px-1 text-sm text-red-500" v-if="validationErrors">
    <template v-for="message in validationErrors" :key="message">
      <div>
        {{ message }}
      </div>
    </template>
  </div>
</template>

<script setup>
import { ref } from 'vue';

defineOptions({
  inheritAttrs: false
})

const model = defineModel({
  set: (value) => {
    if (props.onlyValue) {
      return;
    }
    return value
  }
})
const props = defineProps({
  label: {
    type: String,
    default: 'Label'
  },
  id: {
    type: String,
    default: 'Name'
  },
  name: {
    type: String,
    default: 'Name'
  },
  autocomplete: {
    type: String,
    default: 'On'
  },
  type: {
    type: String,
    default: 'text'
  },
  disabled: {
    type: Boolean,
    default: false
  },
  required: {
    type: Boolean,
    default: false
  },
  validationErrors: {
    type: Array,
    default: []
  },
  textArea: {
    type: Boolean,
    default: false
  },
  onlyValue: {
    type: Boolean,
    default: false
  }
})

</script>
