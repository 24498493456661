<template>
  <div class="flex flex-row justify-between px-6 py-4">
    <div class="flex flex-col">
      <div>
        <div class="font-medium" :class="{
          'text-gray-900': item.active,
          'text-gray-500 line-through ': !item.active
        }">
          <router-link :to="{ name: 'UserEdit', params: { user_id: item.id } }"
            class="flex items-center gap-2 leading-5">
            {{ item.first_name }} {{ item.last_name }}</router-link>
        </div>
        <div v-text="item.email" class="text-sm text-gray-600"></div>
      </div>
    </div>
    <div v-if="item.ext_tool_id" class="flex items-center justify-center h-full"
      v-tippy="{ content: $t('settings.users.list.ext_tool_id', { id: item.ext_tool_id }), interactive: true, hideOnClick: false, }">
      <font-awesome-icon :icon="['fal', 'link']" size="xl" />
    </div>

  </div>
</template>

<script>
import { $t } from '@/config/i18n';
import { useCompanyStore } from '@/stores/company'

// vue default export
export default {
  props: ['user'],
  components: {},
  setup() {
    return {}
  },
  created() {
    this.item = { ...this.user }
  },
  mounted() {
    this.$echo
      .private('company.' + useCompanyStore().company.id + '.admin')
      .listen('UserUpdated', (payload) => {
        if (payload.user.id == this.item.id) {
          this.item = { ...this.item, ...payload.user }
        }
      })
      .listen('.user.updated', (payload) => {
        if (payload.user.id == this.item.id) {
          this.item = { ...this.item, ...payload.user }
        }
      })
  },
  // beforeUnmount() {
  //   this.$echo.leave('user.' + this.item.id)
  // },
  data() {
    return {
      item: {}
    }
  },
  computed: {},
  methods: {}
}
</script>
