import { captureException, captureMessage } from '@sentry/vue'
import timelinkLogo from './../assets/timelink_bookmark_primaer_elfenbeinRGB.svg'
import timelinkIcon from './../assets/timelink_primary_logo_5f_moosgrauRGB.svg'
import testIcon from './../assets/timelink_signet_5fRGB_animated.svg'
import datetime from '@/lib/datetime'
import { useAuthUserStore } from '@/stores/auth-user'

class NotificationService {
  send(title, body, options = {}) {
    if (!('Notification' in window)) {
      return false
    }
    if (Notification.permission !== 'granted') {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          new Notification(title, {
            body: body,
            ...options
          })
          return true
        }
      })
    } else if (Notification.permission == 'granted') {
      try {
        new Notification(title, {
          body: body,
          ...options
        })
        return true
      } catch (error) {
        if (
          error instanceof TypeError &&
          error.message.includes('Use ServiceWorkerRegistration.showNotification() instead')
        ) {
          //
        } else {
          captureException(error)
        }
        return false
      }
    } else {
      captureMessage('No permission for notification and not requestable!')
    }
    return false
  }

  canRequestNotificationPermission() {
    if (!('Notification' in window)) {
      return false
    }
    if (Notification.permission == 'granted') {
      return false
    }
    if (Notification.permission == 'denied') {
      return false
    }
    return true
  }

  async requestPermission() {
    if (!('Notification' in window)) {
      return false
    }
    return await Notification.requestPermission()
  }

  addDataToNotification(notification) {
    if (
      notification.data != undefined &&
      notification.data != null &&
      typeof notification.data == 'object' &&
      Object.entries(notification.data).length > 0
    ) {
      return
    }
    if (notification.type == 'remindActiveTracking') {
      notification.data = {
        after:
          useAuthUserStore().user?.settings?.notifications?.remindActiveTracking?.after ?? 60
      }
    }
  }

  /**
   *
   * @param {{
   * id: string,
   * type: string,
   * severity: {
   *   level: number,
   *   type: string
   * },
   * data: any[],
   * read_at: string|Date,
   * created_at: string|Date,
   * updated_at: string|Date,
   * user_id:  ?string,
   * company_id:  ?string,
   * options: ?object,
   * actions: ?object,
   * until: ?number
   * }} notification
   */
  addActionToNotification(notification) {
    if (notification.actions != undefined && notification.actions != null) {
      return
    }
    if (notification.type == 'dataIntegration.failed') {
      notification.actions = {
        i18n: 'goto',
        route: { name: 'Integrations' }
      }
    }
  }

  /**
   *
   * @param {{
   * id: string,
   * type: string,
   * severity: {
   *   level: number,
   *   type: string
   * },
   * data: any[],
   * read_at: string|Date,
   * created_at: string|Date,
   * updated_at: string|Date,
   * user_id:  ?string,
   * company_id:  ?string,
   * options: ?object,
   * actions: ?object,
   * until: ?number
   * }} notification
   * @returns
   */
  addOptionsToNotification(notification) {
    if (notification.options != undefined && notification.options != null) {
      return
    }
    if (notification.type == 'dataIntegration.failed') {
      notification.options = {
        store: false,
        browser: {
          requireInteraction: true,
          silent: false
        }
      }
    }
  }

  /**
   *
   * @param {import('@/stores/notifications').Notification} notification
   * @returns
   */

  addUntilToNotification(notification) {
    const authUserStore = useAuthUserStore()
    if (notification.until != undefined && notification.until != null) {
      return
    }

    if (notification.type == 'dataIntegration.failed') {
      notification.until = Date.now() + 1000 * 60 * 60 * 24 * 7
    }
  }
}

export default new NotificationService()
