import { onBeforeUnmount, onMounted, ref } from "vue";

export function useKeySave(callbackFunction) {
    const callbacks = ref([])

    function keyDown(event) {
        if (
            (event.metaKey || event.ctrlKey) &&
            (event.key == 'Enter' || event.code == 'Enter' || event.code == 'NumpadEnter')
        ) {
            callbacks.value.forEach((cb) => {
                if (typeof cb == 'function') {
                    cb()
                }
            })
        }
    }

    function addCallback(callbackFunction) {
        if (typeof callbackFunction == 'function') {
            callbacks.value.push(callbackFunction)
        }
    }

    addCallback(callbackFunction)

    onMounted(() => {
        window.addEventListener('keydown', keyDown)
    })

    onBeforeUnmount(() => {
        window.removeEventListener('keydown', keyDown)
    })

    return {
        callbacks,
        addCallback
    }
}