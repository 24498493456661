<template>
  <form @submit.prevent="save" ref="form">
    <BaseModal v-model="showModal" @close-modal="closeModal">
      <template v-slot:header> {{ $t('create_service') }} </template>
      <div class="px-4 py-6 sm:p-8">
        <div class="grid max-w-2xl grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
          <CPInputs v-model="service" :validation-errors="validationErrors" :can-edit="canEdit"></CPInputs>
        </div>
      </div>
      <template v-slot:footer>
        <div class="flex items-center justify-end w-full space-x-4">
          <BaseButton class="button-gray" @click="closeModal" type="button">{{
            $t('cancel')
            }}</BaseButton>

          <BaseButton class="button-mossgray" type="submit" @click="save" :disabled="isLoading">
            {{ $t('create') }}
          </BaseButton>
        </div>
      </template>
    </BaseModal>
  </form>
</template>

<script setup>
import BaseButton from '@/components/general/BaseButton.vue'
import BaseModal from '@/components/modals/BaseModal.vue'
import apiService from '@/services/api.service'
import { useCompanyStore } from '@/stores/company'
import { useAuthUserStore } from '@/stores/auth-user'
import { useAlertsStore } from '@/stores/alerts'
import { captureException } from '@sentry/vue'
import { computed, nextTick, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { $t } from '@/config/i18n'
import { useServicesStore } from '@/stores/services'
import CPInputs from './component/CPInputs.vue'
import timelinkStoresService from '@/services/timelink-stores.service'
import { useKeySave } from '@/composables/keySave'

const companyStore = useCompanyStore()
const authUserStore = useAuthUserStore()
const alertsStore = useAlertsStore()
const servicesStore = useServicesStore()
const route = useRoute()
const isLoading = ref(false)

const props = defineProps(['modelValue'])
const emit = defineEmits(['update:modelValue'])

const showModal = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value)
})

const name_input = ref(null)
watch(
  () => showModal.value,
  async (newVal) => {
    if (newVal) {
      await nextTick()
      name_input.value?.focus()
    }
  }
)

function closeModal() {
  showModal.value = false
  service.value = {
    name: null,
    info: null,
    active: true,
    color: timelinkStoresService.getRandomColorFromPreset()

  }
}

const validationErrors = ref([])

const service = ref({
  name: null,
  acronym: null,
  info: null,
  active: true,
  color: timelinkStoresService.getRandomColorFromPreset()
})
const canEdit = ref(true)

//TODO: Update to work with edit + escape key
const saveOnKeyDown = useKeySave(() => {
  if (!showModal.value) {
    return
  }
  if (isLoading.value) {
    return
  }
  save()
})
async function save() {
  if (companyStore.company.pull_provider || authUserStore.user.admin < 5) {
    alertsStore.error($t('errors.no_permissions'))
    return
  }
  isLoading.value = true
  try {
    let response = await apiService.create(
      import.meta.env.VITE_API_URL + '/api/v1/services',
      service.value
    )
    if (response.success) {
      if (response.data) {
        servicesStore.addOrUpdate(response.data)
      }
      showModal.value = false
      alertsStore.success($t('successfully.created_service', { serviceName: service.value.name }))
      service.value = {
        name: null,
        info: null,
        active: true,
        color: '#183F6A'
      }
    } else {
      alertsStore.error($t('errors.ups_create'))
    }
  } catch (error) {
    if (apiService.checkErrorAndNotify(error)) {
      //
    } else if ((validationErrors.value = apiService.checkForValidationErrorAndConvert(error))) {
      // 
    } else {
      alertsStore.error($t('errors.ups'))
      console.error(error)
      captureException(error)
    }
  }
  isLoading.value = false
}
</script>
