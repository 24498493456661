<template>
    <a :href="props.href" :target="props.target" class="w-full col-span-5 px-4 py-4 hover:font-semibold">
        <slot>Link-Placeholder</slot>
    </a>
</template>

<script setup>
const props = defineProps({
    href: {
        default: '#',
        type: String
    },
    target: {
        default: '_blank',
        type: String
    }
})
</script>