<template>
  <div x-show="" class="fixed left-0 right-0 z-50 flex justify-center max-w-lg mx-auto bottom-12 min-w-30">
    <div class="flex flex-col w-full max-w-lg space-y-2 overflow-y-auto max-h-48">
      <TransitionGroup>
        <template v-for="alert in alertsStore.alerts" :key="alert.id">
          <div class="flex justify-between w-full space-x-4 drop-shadow" :class="{
            'bg-blue-500 text-white': alert.type == 'info',
            'bg-green-500 text-white': alert.type == 'success',
            'bg-red-500 text-white': alert.type == 'error',
            'bg-yellow-500 text-white': alert.type == 'warning',
            'bg-mossgray text-white': !types.includes(alert.type),
            'rounded-xl': alert.message.length > 80,
            'rounded-full': alert.message.length <= 80
          }">
            <div class="px-6 py-2">
              <span>{{ alert.message }}</span>
            </div>
            <div class="flex items-center pr-4 text-lg leading-3">
              <button type="button" :class="{
                'text-white': alert.type == 'info',
                'text-white': alert.type == 'success',
                'text-white': alert.type == 'error',
                'text-white': alert.type == 'warning',
                'text-apricot': !types.includes(alert.type)
              }" @click="close(alert.id)">
                <font-awesome-icon :icon="['fa-kit', 'tl-cross']" />
              </button>
            </div>
          </div>
        </template>
      </TransitionGroup>
    </div>
  </div>
</template>

<script setup>
import { $t } from '@/config/i18n'
import { useAlertsStore } from '@/stores/alerts'
const alertsStore = useAlertsStore()
const types = ['success', 'info', 'warning', 'error']
// alertsStore.$subscribe((mutation, state) => {
//   console.log(mutation, state)
// })
function close(id) {
  alertsStore.removeId(id)
}
</script>
