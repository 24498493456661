<template>
  <TemplateComponent>
    <loading-spinner v-model="isLoading" :fullPage="true"></loading-spinner>
    <div class="flex items-center justify-between w-full py-3 mb-3">
      <h1 class="text-2xl font-bold">
        {{ $t('edit_user') }}
      </h1>
    </div>

    <div class="pb-16 space-y-8 divide-y divide-gray-900/10">
      <div class="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
        <div class="px-4 sm:px-0">
          <h2 class="text-lg font-bold leading-7">
            {{ $t('user_edit_header') }}
          </h2>
          <p class="mt-1 text-sm leading-6 text-gray-600">
            {{ $t('user_edit_description') }}
          </p>
        </div>

        <form class="bg-white ring-1 ring-mossgray-200 sm:rounded-3xl md:col-span-2" @submit.prevent="save">
          <div class="px-4 py-6 sm:p-8">
            <div class="p-4 mb-5 rounded-lg bg-mossgray-100" v-if="authUserStore.user.id == $route.params.user_id">
              <div class="flex align-center">
                <div class="flex-shrink-0">
                  <font-awesome-icon :icon="['fa-kit', 'tl-info']" class="text-mossgray" />
                </div>
                <div class="ml-3">
                  <p class="text-mossgray leading-0">{{ $t('errors.edit_own_user') }}</p>
                </div>
              </div>
            </div>

            <div class="grid max-w-2xl grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
              <div class="sm:col-span-3">
                <BaseInput :validation-errors="validationErrors.first_name" :disabled="!canEdit" type="text"
                  name="first_name" id="first_name" autocomplete="first_name" v-model="user.first_name"
                  ref="first_name_input" :required="true">
                  {{ $t('first_name') }}
                </BaseInput>
              </div>
              <div class="sm:col-span-3">
                <BaseInput :validation-errors="validationErrors.last_name" :disabled="!canEdit" type="text"
                  name="last_name" id="last_name" autocomplete="last_name" v-model="user.last_name" :required="true">
                  {{ $t('last_name') }}
                </BaseInput>
              </div>
              <div class="sm:col-span-3">
                <BaseInput :validation-errors="validationErrors.email" :disabled="!canEdit" type="email" name="email"
                  id="email" autocomplete="email" v-model="user.email" :required="true">
                  {{ $t('email') }}
                </BaseInput>
              </div>

              <div class="sm:col-span-3 sm:col-start-1">
                <BaseInput :validation-errors="validationErrors.active" :disabled="!canEdit" type="checkbox"
                  name="active" id="active" autocomplete="active" v-model="user.active">
                  {{ $t('active') }}
                </BaseInput>
              </div>

              <div class="sm:col-span-3">
                <BaseInput :validation-errors="validationErrors.admin" :disabled="!canEdit" type="checkbox" name="admin"
                  id="admin" autocomplete="admin" v-model="user.admin">
                  {{ $t('admin') }}
                </BaseInput>
              </div>

              <div class="sm:col-span-3">
                <BaseInput :validation-errors="validationErrors.password" :disabled="!canEdit" type="password"
                  name="password" id="password" autocomplete="new-password" v-model="user.password">
                  {{ $t('password') }}
                </BaseInput>
              </div>
              <div class="sm:col-span-3">
                <BaseInput :validation-errors="validationErrors.password_confirmation" :disabled="!canEdit"
                  type="password" name="password_confirmation" id="password_confirmation" autocomplete="new-password"
                  v-model="user.password_confirmation">
                  {{ $t('password_confirmation') }}
                </BaseInput>
              </div>
              <div class="sm:col-span-3">
                <BaseInput :disabled="companyStore.company.pull_provider" type="text" name="ext_tool_id"
                  id="ext_tool_id" autocomplete="Off" v-model="user.ext_tool_id">
                  {{ $t('ext_tool_id') }}
                </BaseInput>
              </div>
            </div>
          </div>

          <div class="flex items-center justify-between px-4 py-4 border-t gap-x-6 border-gray-900/10 sm:px-6" :class="{
          }">
            <BaseButton class="button-red" type="button" @click="showDeleteModal = true" :disabled="!canEdit">
              {{ $t('delete') }}
            </BaseButton>
            <div class="flex flex-row items-center gap-x-6">
              <div v-if="companyStore.company.pull_provider">
                {{ $t('managed_through') }}
                <span class="font-medium">{{ companyStore.company.pull_provider }}</span>.
              </div>
              <button type="submit" class="button-mossgray" :disabled="isLoading || !canEdit">
                {{ $t('save') }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <BaseModal v-model="showDeleteModal" @close-modal="showDeleteModal = false" v-model:loading="deleteIsLoading">
      <template #header> {{ $t('confirm_deletion') }} </template>

      <div class="p-6">
        <span>
          {{ $t('confirm_deletion_descriptions.user') }}
        </span>
      </div>

      <template #footer>
        <div class="flex justify-end w-full gap-4">
          <BaseButton class="button-gray" type="button" @click="showDeleteModal = false" ref="cancelDeleteModal">
            {{ $t('cancel') }}</BaseButton>
          <BaseButton class="button-red" :disabled="!timer || timer.seconds > 0" @click="deleteUser"><span class="w-6"
              v-if="timer && timer.seconds > 0"> ({{ timer.seconds }}) </span>{{ $t('delete') }}</BaseButton>
        </div>
      </template>
    </BaseModal>
  </TemplateComponent>
</template>

<script setup>
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import apiService from '@/services/api.service'
import BaseButton from '@/components/general/BaseButton.vue'
import BaseInput from '../general/BaseInput.vue'
import timelinkStoresService from '@/services/timelink-stores.service'
import { useCompanyStore } from '@/stores/company'
import { useAuthUserStore } from '@/stores/auth-user'
import BaseModal from '@/components/modals/BaseModal.vue'
import { useTimer } from 'vue-timer-hook'
import logger from '@/services/logger.service'
import { AxiosError } from 'axios'
import { captureException, captureMessage } from '@sentry/vue'
import { useAlertsStore } from '@/stores/alerts'
import TemplateComponent from '@/components/settings/TemplateComponent.vue'
import { computed, nextTick, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { $t } from '@/config/i18n'

const timelinkService = timelinkStoresService
const companyStore = useCompanyStore()
const authUserStore = useAuthUserStore()
const alertsStore = useAlertsStore()
const route = useRoute()
const router = useRouter()

const user = ref({
  first_name: '',
  last_name: '',
  email: '',
  active: true,
  admin: 0,
  password: null,
  password_confirmation: null
})
const validationErrors = ref([])
const isLoading = ref(true)
const showDeleteModal = ref(false)
const timer = ref(null)
watch(() => showDeleteModal.value, async (newVal) => {
  if (newVal) {
    timer.value = useTimer(new Date(Date.now() + 2 * 1000), true)
    await nextTick()
  }
})
const deleteIsLoading = ref(false)

const isAdmin = computed(
  {
    get: () => {
      return user.value.admin == 9
    },
    set: (value) => {
      if (value) {
        user.value.admin = 9
      }
      else {
        user.value.admin = 0
      }
    }
  }
)
const canEdit = computed(() => {
  return authUserStore.user.admin >= 9 &&
    authUserStore.user.id != route.params.user_id
})

onMounted(() => {
  fetch()
})
async function fetch() {
  isLoading.value = true
  try {
    let data = await apiService.fetchId(
      import.meta.env.VITE_API_URL + '/api/v1/users',
      route.params.user_id,
      {}
    )
    user.value = data.data
  } catch (error) {
    if (error?.response?.status == 404) {
      alertsStore.error($t('errors.user.not_found'))
      router.push({ name: 'UsersList' })
      return
    } else if (error?.response?.status == 403) {
      alertsStore.error($t('errors.no_permissions'))
      router.push({ name: 'UsersList' })
      return
    } else {
      logger.error(error)
    }
    // captureException(error)
  }
  isLoading.value = false
}
async function save() {
  if (authUserStore.user.admin < 9) {
    alertsStore.error($t('errors.no_permissions'))
    return
  }
  if (user.value.password != null) {
    if (user.value.password != user.value.password_confirmation) {
      validationErrors.value = {
        'password': [
          $t('errors.passwords_do_not_match')
        ]
      }
      return
    }
  }
  isLoading.value = true
  try {
    const activeState = user.value.active
    let response = await apiService.update(
      import.meta.env.VITE_API_URL + '/api/v1/users',
      route.params.user_id,
      {
        first_name: user.value.first_name,
        last_name: user.value.last_name,
        email: user.value.email,
        active: user.value.active,
        admin: user.value.admin,
        ...((user.value.password) ? {
          password: user.value.password,
          password_confirmation: user.value.password_confirmation
        } : {}),
        // TODO: Include ext_tool_id if it should be changeable
      }
    )
    if (response.success) {
      alertsStore.successfullySaved()
      user.value = { ...response.data }
      if (user.value.active != activeState && activeState) {
        alertsStore.warning($t('settings.user.could_not_be_activated'), 10)
      }
    } else {
      alertsStore.error($t('errors.ups'))
      captureMessage('Save user was not successfully.', [response])
    }
  } catch (error) {
    if (apiService.checkErrorAndNotify()) {
      //
    } else if ((validationErrors.value = apiService.checkForValidationErrorAndConvert(error))) {
      //
    } else {
      alertsStore.error($t('errors.ups'))
      captureException(error)
    }
  }
  isLoading.value = false
}
async function deleteUser() {
  if (authUserStore.user.admin < 9) {
    alertsStore.error($t('errors.no_permissions'))
    return
  }
  deleteIsLoading.value = true
  try {
    let data = await apiService.delete(
      import.meta.env.VITE_API_URL + '/api/v1/users',
      route.params.user_id,
      {},
      () => {
        // TODO: Add better handling
      }
    )
    if (data.success) {
      router.push({
        name: 'UsersList'
      })
    } else {
      alertsStore.error($t('errors.ups'))
    }
  } catch (error) {
    if (
      error.response.status == 403 &&
      error.response.data.message == $t('errors.no_subscription')
    ) {
      // TODO: Add better error handling!
      alert($t('errors.no_subscription'))
      deleteIsLoading.value = false
    }
  }
}
</script>
